import ConnectWithUs from '../ConnectWithUs';
import { useContext, useMemo } from 'react';
import JsxParser from 'react-jsx-parser';
import { useSelector } from 'react-redux';

import Disclaimer from 'components/Disclaimer';
import FooterList from 'components/FooterList';
import Icon from 'components/Icon';
import InverseLogo from 'components/InverseLogo';
import {
    finalExpensePlanType,
    medicarePlanType
} from 'components/PlanTypeModal/constants';

import phoneContext from 'contexts/phone';
import purlAgentContext from 'contexts/purlAgentContext';
import zipcodeContext from 'contexts/zipCode';

import formatPhoneNumber from 'utilities/formatPhoneNumber';
import scrollToTop from 'utilities/scrollToTop';

import envelopeImg from './images/envelope.svg';
import phoneImg from './images/phone.svg';

import {
    companyLinks,
    companyLinks1,
    disclaimerText,
    disclaimerText1,
    disclaimerText2,
    disclaimerText2WhenZipAvailable,
    disclaimerText3,
    disclaimerText4,
    disclaimerText5,
    learningCenterLinks
} from './constants';

import styles from './styles.module.scss';

const Footer = ({ className = '', shouldHideLinks = false }) => {
    const { phone } = useContext(phoneContext);

    const { purlAgentData } = useContext(purlAgentContext);
    const zipCodeContextMap = useContext(zipcodeContext);
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );

    const { email, virtualNumber } = purlAgentData || {};

    const { text = '' } = phone;

    const questionsLinks = [
        {
            link: '/',
            text: `Call ${text} to speak with a licensed insurance agent`
        }
    ];

    const agentContactDetails = purlAgentData && [
        {
            link: `mailto:${email}`,
            externalURL: true,
            text: (
                <>
                    <Icon
                        className={`${styles.envelopeIcon}`}
                        image={envelopeImg}
                    />{' '}
                    {email}
                </>
            )
        },
        {
            link: `tel:${virtualNumber}`,
            externalURL: true,
            text: (
                <>
                    <Icon className={`${styles.phoneIcon}`} image={phoneImg} />{' '}
                    {formatPhoneNumber(virtualNumber?.substring(2))}
                </>
            )
        }
    ];

    const showHomeFooterContent =
        window.location.href.indexOf('final') === -1 ||
        window.location.pathname === '/';

    const showData = () => {
        if (purlAgentData) {
            if (
                (purlAgentData.isMedicareAgent &&
                    selectedPlanType === medicarePlanType) ||
                (purlAgentData.isFinalExpenseAgent &&
                    selectedPlanType === finalExpensePlanType)
            ) {
                return true;
            }
        }
        return false;
    };

    const wrapPlanCount = useMemo(
        () => zipCodeContextMap?.planCount.toLocaleString(),
        [zipCodeContextMap]
    );

    const getDiscliamer2Text = useMemo(
        () =>
            zipCodeContextMap.zipCode
                ? disclaimerText2WhenZipAvailable
                : disclaimerText2,
        [zipCodeContextMap.zipCode]
    );

    return (
        <footer
            className={`${styles.footer} ${className} ${
                shouldHideLinks ? styles.footerNoLinks : ''
            }`}
            data-gtm="footer"
            id="plan-footer"
        >
            <InverseLogo
                className={styles.logo}
                linkClassName={styles.logoLinkClassName}
            />

            {!shouldHideLinks && (
                <FooterList
                    className={styles.learningCenter}
                    items={learningCenterLinks}
                    onClick={scrollToTop}
                    title="Know the Basics"
                />
            )}

            {!shouldHideLinks && (
                <FooterList
                    className={styles.company}
                    items={
                        purlAgentData && showData()
                            ? companyLinks1
                            : companyLinks
                    }
                    onClick={scrollToTop}
                    title="Company"
                />
            )}

            {!shouldHideLinks && (
                <FooterList
                    className={styles.questions}
                    items={
                        purlAgentData && showData()
                            ? agentContactDetails
                            : questionsLinks
                    }
                    onClick={scrollToTop}
                    title="Questions?"
                />
            )}
            <ConnectWithUs className={styles.connectWithUs} />

            <Disclaimer className={styles.disclaimer}>
                <JsxParser
                    bindings={{
                        disclaimerLink: styles.disclaimerLink
                    }}
                    jsx={`
                  ${
                      window.location.href.indexOf('final') === -1
                          ? `<p class="${
                                styles.disclaimerText
                            }">${disclaimerText}</p>
                  <p class="${styles.disclaimerText}">${disclaimerText1}</p>
                  <p class="${
                      !showHomeFooterContent && styles.disclaimerTextShow
                  } ${styles.disclaimerText}">${getDiscliamer2Text.replace(
                                /\$\{([^}]+)\}/g,
                                (_, group) => {
                                    return group.trim() === 'wrapPlanCount'
                                        ? wrapPlanCount
                                        : zipCodeContextMap[group.trim()];
                                }
                            )}</p>
                  <p class="${
                      !showHomeFooterContent && styles.disclaimerTextShow
                  } ${styles.disclaimerText}">${disclaimerText3}</p>
                  <p class="${styles.disclaimerText}">${disclaimerText4}</p>
                  <p class="${
                      (window.location.href.includes('medicare') ||
                          window.location.href.includes('quick-profile')) &&
                      styles.disclaimerTextShow
                  } ${styles.disclaimerText}">${disclaimerText5}</p>
                  `
                          : `<p class="${
                                window.location.href.indexOf('final') === -1 &&
                                styles.disclaimerTextShow
                            } ${styles.disclaimerText}">${disclaimerText5}</p>`
                  }
                  `}
                />
            </Disclaimer>
        </footer>
    );
};

export default Footer;
