import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Text from 'components/Text';

import { formatPhoneNumberWithBrackets } from 'utilities/formatPhoneNumber';
import { QUICK_PROFILE_ROUTE } from 'utilities/routes';

import envelope from './images/envelope.svg';
import phone from './images/phone.svg';

import styles from './styles.module.scss';

export const PurlBannerContactInfo = ({ purlAgentData, isMobile }) => {
    const history = useHistory();
    const { virtualNumber, agentProfessionalInfoResponse } =
        purlAgentData || {};
    const { agentOfficeLocation: office, formattedHours = '' } =
        agentProfessionalInfoResponse || {};
    const { city = '', state = '' } = office || {};
    const availableHours = formattedHours?.split(',').filter(Boolean) || [];
    let phoneNumber = virtualNumber?.substring(2);
    return (
        <div className={styles.purlAgentContactContainer}>
            {isMobile && (
                <Text className={styles.purlAgentSubText}>
                    {(city || state) && (
                        <>
                            <Text>
                                {[city, state].filter(Boolean).join(', ')}
                            </Text>

                            <span className={styles.hideMobile}>|</span>
                        </>
                    )}
                    <div className={styles.availableHourContainer}>
                        {availableHours.map(hour => (
                            <Text>{hour}</Text>
                        ))}
                    </div>
                </Text>
            )}
            <a
                className={styles.link}
                href={`mailto:${purlAgentData.email}`}
                rel="noreferrer"
            >
                {purlAgentData.email}{' '}
                <Icon className={`${styles.envelopeIcon}`} image={envelope} />
            </a>
            <a
                className={styles.link}
                href={`tel:${phoneNumber}`}
                rel="noreferrer"
            >
                {formatPhoneNumberWithBrackets(phoneNumber)}{' '}
                <Icon className={`${styles.phoneIcon}`} image={phone} />
            </a>
            {window.location.pathname !== QUICK_PROFILE_ROUTE && (
                <div className={styles.getSyncContainer}>
                    <Button
                        label="Get Synced"
                        className={styles.getSyncBtn}
                        onClick={() => history.push(QUICK_PROFILE_ROUTE)}
                    />
                </div>
            )}
        </div>
    );
};
