import { useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddressWithInOrOutNetworkIcon from 'components/AddressWithInOrOutNetworkIcon';

import styles from './styles.module.scss';

export const AddressWithCheckBox = ({
    address,
    handleSelect,
    isChecked = false,
    maxReached = false
}) => {
    const [checked, setChecked] = useState(isChecked);
    return (
        <div
            className={`${styles.addressItem} ${checked ? styles.active : ''}`}
            id={address.id}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        id={address.id}
                        checked={checked}
                        disabled={!checked && maxReached}
                        onChange={e => {
                            setChecked(e.target.checked);
                            handleSelect(e.target.checked, address.id);
                        }}
                    />
                }
                label={<AddressWithInOrOutNetworkIcon address={address} />}
            />
        </div>
    );
};

export default AddressWithCheckBox;
