import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Heading3 from 'components/Heading3';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';
import YourHealthPlanModalContent from 'components/YourHealthPlanModalContent';

import dataLayer from 'utilities/dataLayer';
import { COUNTY_FIPS_KEY, ZIP_CODE_KEY } from 'utilities/storageKeys';

import { headerTitle, saveButtonLabel } from './constants';
import { setHealthPlanContentModal } from './slice';

import styles from './styles.module.scss';

import { get } from 'temp/utilities/storage';

const YourHealthPlanModal = ({
    insuranceImage,
    plansDetails,
    onClose: onCloseParent
}) => {
    const history = useHistory();
    const healthPlanModal = useSelector(
        state => state.healthPlanModalDetails?.healthPlanContentModal
    );

    useEffect(() => {
        if (healthPlanModal) {
            dataLayer('modal_appear', headerTitle);
        }
        document
            .getElementById('infoModalIcon')
            ?.setAttribute(
                'style',
                `background-color:${plansDetails?.carrierColor}`
            );
    }, [healthPlanModal]);
    const PlanDate = ({ label, data, className = styles.heading }) => (
        <div className={styles.data}>
            <div>
                <Heading3 className={className} text={`${label}:`} />
            </div>

            <Text className={styles.policyDetails}>{data}</Text>
        </div>
    );
    const dispatch = useDispatch();

    const onClose = async () => {
        dispatch(setHealthPlanContentModal(false));
        onCloseParent?.();
    };

    const footerButtonClicked = async e => {
        dispatch(setHealthPlanContentModal(false));
        const zip = get(ZIP_CODE_KEY);
        const fips = get(COUNTY_FIPS_KEY);
        history.push(
            `/plan/${plansDetails.confirmationNumber}/${zip}/${fips}?from=Profile`
        );
    };

    return (
        <Modal className={styles.modal} isOpen={healthPlanModal}>
            <ModalWindow
                closeIconClassName={styles.closeIconClassName}
                contentClassName={styles.contentClassName}
                footerButtonClassName={styles.footerButtonClassName}
                footerButtonClicked={footerButtonClicked}
                footerClassName={styles.footerClassName}
                footerLabel={saveButtonLabel}
                hideFooter={!plansDetails?.hasPlanDetails}
                headerClassName={styles.headerClassName}
                headerTitle={headerTitle}
                modalWindow={styles.modalWindow}
                onClose={() => onClose()}
                headerHeadingClassName={styles.headerHeadingClassName}
                modalHeaderTitle={styles.modalHeaderTitle}
            >
                <div className={styles.content}>
                    <div className={styles.insuranceIcon}>
                        <div
                            className={styles.infoIcon}
                            id="infoModalIcon"
                            style={{
                                backgroundColor: plansDetails?.carrierColor
                            }}
                        >
                            <Text
                                text={plansDetails?.planName}
                                className={styles.planName}
                            />
                            <div className={styles.policyDetailsContainer}>
                                <div className={styles.detailsContainer}>
                                    <PlanDate
                                        label="Status"
                                        data={`${
                                            plansDetails?.policyStatus
                                                ? 'Active'
                                                : 'N/A'
                                        }`}
                                    />
                                    <PlanDate
                                        className={styles.heading3}
                                        label="Policy Number"
                                        data={plansDetails?.policyNumber}
                                    />
                                </div>
                                <Icon
                                    className={styles.icon}
                                    image={plansDetails?.carrierIconURL}
                                    id="insuranceIcon"
                                />
                            </div>
                        </div>
                    </div>
                    <Text
                        text={'Plan Details'}
                        className={styles.planDetailsLabel}
                    />
                    <YourHealthPlanModalContent
                        className={styles.manageAccountContent}
                        plansDetails={plansDetails}
                    />
                </div>
            </ModalWindow>
        </Modal>
    );
};

export default YourHealthPlanModal;
