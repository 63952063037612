import { useEffect, useState } from 'react';
import { getPhoneData } from './data';
import { useDispatch, useSelector } from 'react-redux';
import { setArticleProductType } from './slice';
import { matchPath } from 'react-router-dom/cjs/react-router-dom.min';
import { setSelectedPlanType } from 'components/PlanTypeModal/slice';
import { finalExpensePlanType, medicarePlanType } from 'components/PlanTypeModal/constants';

export const usePhone = () => {
    const dispatch = useDispatch();
    const [phone, setPhone] = useState('');
    const [phoneData, setPhoneData] = useState('');
    const articleProductType = useSelector(state => state.mainDetails.articleProductType);

    const populateData = (phoneData) =>{
        let number = phoneData.number;
        let text = phoneData.text;
        let datagtm='generic';
        if(matchPath(window.location.pathname,{ path:'/learning-center/:articleName' })){
            if(articleProductType === 'medicare'){
                // number = phoneData.medicareNumber;
                datagtm='medicare';
                dispatch(setSelectedPlanType(medicarePlanType));
            }
            else if(articleProductType === 'final expense'){
                // number = phoneData.finalExpenseNumber;
                datagtm='final expense';
                dispatch(setSelectedPlanType(finalExpensePlanType));
            }
        }
        else if(window.location.pathname.includes('medicare')){
            // number = phoneData.medicareNumber;
            datagtm='medicare';
            dispatch(setSelectedPlanType(medicarePlanType));
        }
        else if(window.location.pathname.includes('final-expense')){
            // number = phoneData.finalExpenseNumber;
            datagtm='final expense';
            dispatch(setSelectedPlanType(finalExpensePlanType));
        }
        text= text.startsWith("TTY") ? `${number} | ${text}` : text;
        setPhone({...phoneData, number: number, text: text, datagtm: datagtm});
    }

    useEffect(() => {
        (async () => {
            dispatch(setArticleProductType(''));
            const phoneData = await getPhoneData();
            setPhoneData(phoneData[0]);
            populateData(phoneData[0])
        })();
    },[]);
    useEffect(()=>{
        phoneData && populateData(phoneData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[window.location.pathname, articleProductType])

    return {
        phone,
        setPhone
    };
};
