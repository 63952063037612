import Body2 from 'components/Body2';
import Icon from 'components/Icon';

import handleEvent from 'utilities/handleEvent';

import image from './image.png';

import styles from './styles.module.scss';

const handleClick = onClick => handleEvent(onClick);

const EditLink = ({
    onClick,
    text = '',
    displayIconOnLeft = false,
    className = '',
    iconClassName = '',
    icon,
    noIcon = false,
    id = 'editLink'
}) => (
    <Body2
        onClick={handleClick(onClick)}
        className={`${styles.editLink} ${className}`}
        text={
            <>
                {displayIconOnLeft && !noIcon && (
                    <Icon
                        className={`${styles.icon} ${iconClassName}`}
                        image={icon ? icon : image}
                    />
                )}
                {text}
                {!displayIconOnLeft && !noIcon && (
                    <Icon
                        className={`${styles.icon} ${iconClassName}`}
                        image={icon ? icon : image}
                    />
                )}
            </>
        }
        id={id}
    />
);

export default EditLink;
