import { useCallback, useEffect, useState } from 'react';

import { SearchOutlined } from '@mui/icons-material';
import { TextField } from '@mui/material';

import ActionText from 'components/ActionText';
import Heading5 from 'components/Heading5';
import IconWithLink from 'components/IconWithLink';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextAsLink from 'components/TextAsLink';

import handleEvent from 'utilities/handleEvent';
import isFunction from 'utilities/isFunction';

import arrow from './image/arrow.svg';

import styles from './styles.module.scss';

import useDebounce from 'temp/utilities/useDebounce';

// const PER_PAGE = 10;

const TypeAheadDoctor = ({
    addDoctorLoader,
    debounceDelay = 250,
    defaultValue,
    fetchResults,
    multipleFoundLabel = '',
    noResultsMessage = 'No results found under the current search criteria.',
    onChange,
    onClear,
    placeholder = 'Start typing to search',
    radiusMiles,
    zipCode,
    county,
    resultMap,
    singleFoundLabel = '',
    startPrompt = 'Start typing to search.',
    title,
    PER_PAGE = 10
}) => {
    const [showResults, setShowResults] = useState(false);
    const [providersList, setProvidersList] = useState([]);
    const [searchTerm, setSearchTerm] = useState(defaultValue);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [displayShowMore, setDisplayShowMore] = useState(false);

    const label =
        providersList.length === 1 ? singleFoundLabel : multipleFoundLabel;
    const text1 = `${providersList.length || 0} ${label} `;
    const text = `found within ${radiusMiles} miles`;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
    const showNoResultsMessage =
        debouncedSearchTerm && providersList?.length === 0;
    const infoMessage = !searchTerm
        ? startPrompt
        : showNoResultsMessage
        ? noResultsMessage
        : '';

    const searchTermChanged = e => {
        setDisplayShowMore(false);
        setPage(1);
        const newSearchTerm = e?.target?.value;
        setSearchTerm(newSearchTerm);
        setShowResults(false);
        isFunction(onChange) && onChange(newSearchTerm);
    };

    const handleClear = handleEvent(onClear, () => {
        setSearchTerm('');
        setShowResults(false);
    });

    const clearResults = useCallback(() => {
        setProvidersList([]);
        setDisplayShowMore(false);
        setPage(1);
        setShowResults(false);
    }, []);

    const getData = useCallback(
        async ({
            searchTerm,
            signal,
            page,
            radiusMiles,
            zipCode,
            county,
            perPage
        }) => {
            setLoading(true);
            setShowResults(false);
            setError(false);
            setDisplayShowMore(false);

            try {
                const searchResults = await fetchResults({
                    searchTerm,
                    signal,
                    page,
                    radiusMiles,
                    zipCode,
                    county,
                    perPage
                });

                setError(false);
                if (searchResults?.providers) {
                    const results = searchResults?.providers
                        ? searchResults?.providers
                        : [];

                    if (page > 1) {
                        setProvidersList(previousData => [
                            ...previousData,
                            ...results
                        ]);
                    } else {
                        setProvidersList(results);
                    }
                    if (searchResults.total >= PER_PAGE) {
                        setDisplayShowMore(true);
                    }
                } else {
                    clearResults();
                }
            } catch (networkError) {
                clearResults();
                setError(true);
                setDisplayShowMore(false);
            }

            setLoading(false);
            setShowResults(true);
        },
        [clearResults, fetchResults]
    );

    useEffect(() => {
        if (showResults && providersList?.length > PER_PAGE) {
            let index = providersList?.length - PER_PAGE;
            let element = document.getElementById(`doctor-searchItem-${index}`);
            element?.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showResults]);

    useEffect(() => {
        setDisplayShowMore(false);
        setPage(1);
        setShowResults(false);
    }, [radiusMiles, zipCode, county, clearResults]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (debouncedSearchTerm?.length) {
            getData({
                searchTerm: debouncedSearchTerm,
                signal,
                page,
                radiusMiles,
                zipCode,
                county,
                perPage: PER_PAGE
            });
        } else {
            clearResults();
        }

        return () => {
            controller.abort();
        };
        // this effect should only be rerun when the debounced Search Term changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, page, radiusMiles, zipCode, county]);

    useEffect(() => {
        setLoading(addDoctorLoader);
    }, [addDoctorLoader]);

    useEffect(() => {
        document.getElementById(`doctor-searchItem-${page * PER_PAGE}`) &&
            document
                .getElementById(`doctor-searchItem-${page * PER_PAGE}`)
                .scrollIntoView();
    }, [page]);

    return (
        <>
            <Text className={styles.title} text={title} />

            <TextField
                autoFocus
                className={styles.textField}
                onChange={searchTermChanged}
                defaultValue={defaultValue}
                error={error}
                variant="outlined"
                inputProps={{
                    maxLength: 100,
                    type: 'search'
                }}
                InputProps={{
                    endAdornment: (
                        <SearchOutlined className={styles.searchIcon} />
                    )
                }}
                placeholder={placeholder}
                value={searchTerm}
                id="doctorSearchInput"
            />

            {searchTerm && (
                <ActionText
                    className={styles.actionText}
                    onClick={handleClear}
                    id="cancelSearchButton"
                >
                    Cancel
                    <span className={styles.hideOnMobile}>Search</span>
                </ActionText>
            )}

            <div className={styles.foundText} id="foundDoctorCount">
                <Heading5 className={styles.heading5} text={text1} />

                <Text className={styles.textItalic} text={text} />
            </div>

            {loading ? <Spinner /> : null}

            {!loading && infoMessage && (
                <Text
                    className={styles.infoMessage}
                    text={infoMessage}
                    id="infoMessage"
                />
            )}

            {!loading && showResults && (
                <ul className={styles.resultsList}>
                    {isFunction(resultMap) && providersList?.map(resultMap)}
                </ul>
            )}
            {!loading && displayShowMore && (
                <>
                    <div className={styles.divider} />
                    <IconWithLink
                        caption={'Show More'}
                        className={styles.iconWithLink}
                        iconClassName={styles.iconClassName}
                        image={arrow}
                        isImageClickable={true}
                        labelClassName={styles.labelClassName}
                        onClick={() => setPage(page + 1)}
                        isTextDisplayRight={false}
                    />
                </>
            )}
        </>
    );
};

export default TypeAheadDoctor;
