import { useMemo } from 'react';

import Body2 from 'components/Body2';
import Icon from 'components/Icon';
import RadioWithLabel from 'components/RadioWithLabel';
import Text from 'components/Text';

import fullAddressTwoLine from 'utilities/fullAddressTwoLine';

import OutofNetwork_Icon from 'temp/components/PrescriptionsTable/icons/OutofNetwork_Icon.svg';
import greenCheckImage from 'temp/components/PrescriptionsTable/icons/greenCheckImage.svg';

import styles from './styles.module.scss';

const PharmacyListItem = ({
    active,
    address1,
    address2,
    checked,
    city,
    id,
    inNetworkIcon = false,
    inOutNetwork,
    isRadio = false,
    key,
    name,
    onClick,
    state,
    value,
    zipCode,
    selectPharmacy
}) => {
    const CoveredCheck = (
        <Icon className={styles.checkIcon} image={greenCheckImage} />
    );
    const NotCoveredCheck = (
        <Icon className={styles.checkIcon} image={OutofNetwork_Icon} />
    );

    const htmlContent = useMemo(
        () => (
            <div
                className={`${styles.addressBlock} ${
                    inNetworkIcon ? styles.networkIcon : ''
                }`}
            >
                {inNetworkIcon &&
                    (inOutNetwork ? CoveredCheck : NotCoveredCheck)}
                <div className={styles.addressContainer}>
                    <Text className={styles.text} text={name} />
                    <Body2
                        text={fullAddressTwoLine({
                            streetLine1: address1,
                            streetLine2: address2,
                            city,
                            state,
                            zipCode
                        })}
                        className={styles.address}
                    />
                </div>
            </div>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inNetworkIcon]
    );

    return (
        <li
            className={`${styles.pharmacyListItem}  ${
                checked || active ? styles.active : ''
            } ${
                id === 'pharmacy-searchItem-0' && styles.firstPharmacyListItem
            } ${selectPharmacy}`}
            key={key}
            id={id}
        >
            {isRadio ? (
                <RadioWithLabel
                    checked={checked}
                    className={styles.radioLabel}
                    id={`${id}_${key}`}
                    label={htmlContent}
                    name={name}
                    onChange={e => {
                        onClick(e.target.value);
                    }}
                    value={value}
                />
            ) : (
                <>{htmlContent}</>
            )}
        </li>
    );
};

export default PharmacyListItem;
