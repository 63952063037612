import {
    CONTACT_AN_AGENT_ROUTE,
    FINAL_EXPENSE_AGENT,
    FINAL_EXPENSE_PLANS_ROUTE,
    LEARNING_CENTER_ROUTE,
    PLANS_ROUTE,
    PROFILE_ROUTE
} from 'utilities/routes';

const data = [
    {
        title: 'Profile',
        navigationLink: PROFILE_ROUTE
    },
    {
        title: 'Products',
        navigationLink: PLANS_ROUTE,
        medicareLink: PLANS_ROUTE,
        finalExpenseLink: FINAL_EXPENSE_PLANS_ROUTE
    },
    {
        title: 'Find an Agent',
        navigationLink: CONTACT_AN_AGENT_ROUTE,
        medicareLink: CONTACT_AN_AGENT_ROUTE,
        finalExpenseLink: FINAL_EXPENSE_AGENT
    },
    {
        title: 'Learning Center',
        navigationLink: LEARNING_CENTER_ROUTE,
        finalExpenseLink: LEARNING_CENTER_ROUTE
    }
];

export default data;
