import { useSelector } from 'react-redux';

import Heading3 from 'components/Heading3';
import PharmacyAddedListItem from 'components/PharmacyAddedListItem';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

const PharmacyAddedListItems = ({ pharmacyList, handleDeletePharmacy }) => {
    const selectedPlan = useSelector(
        state => state.pharmacyDetails.selectedPlan
    );
    return (
        pharmacyList && (
            <div className={styles.pharmacyAddedListItems}>
                {selectedPlan && (
                    <Heading3
                        className={styles.heading3}
                        text={selectedPlan.planName}
                    />
                )}
                {pharmacyList?.length ? (
                    pharmacyList.map((pharmacy, index) => (
                        <PharmacyAddedListItem
                            deletePharmacy={handleDeletePharmacy}
                            key={index}
                            pharmacyDetails={pharmacy}
                        />
                    ))
                ) : (
                    <Spinner />
                )}
            </div>
        )
    );
};

export default PharmacyAddedListItems;
