import { memo } from 'react';

import Heading2 from 'components/Heading2';
import { medicarePlanType } from 'components/PlanTypeModal/constants';

import styles from './styles.module.scss';

const PurlBannerFindAgent = ({ selectedAgentType }) => {
    return (
        <div className={styles.purlAgentNameContainer}>
            <div className={styles.nameWithStatus}>
                <Heading2
                    className={styles.purlAgentName}
                    text={`Find a ${
                        selectedAgentType === medicarePlanType
                            ? 'Medicare'
                            : 'Final Expense'
                    } Agent`}
                ></Heading2>
            </div>
        </div>
    );
};

export default memo(PurlBannerFindAgent);
