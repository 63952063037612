import styles from './styles.module.scss';

const LinkToNewTab = ({ text = '', url = '', className = '', id = 'link' }) => {
    const linkText = text || url;

    return (
        <a
            className={`${className} ${styles.linkText}`}
            href={url}
            target="_blank"
            rel="noreferrer"
            id={id}
        >
            {linkText}
        </a>
    );
};

export default LinkToNewTab;
