export const headerTitle = 'Medicaid Benefits';

export const headerText =
    'If you qualify for Medicaid and Medicare, then you may be eligible for Special Needs Plans.';

export const headerIconAlt = '';
export const plansButtonLabel = 'Continue';
export const saveButtonLabel = 'Save';

export const medicaidOptions = {
    Yes: 1,
    No: 0,
    'I’m not sure': 2
};
