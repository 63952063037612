import { useCallback, useEffect } from 'react';

import ListItem from 'components/ListItem';
import ModalFooter from 'components/ModalFooter';
import ModalHeader from 'components/ModalHeader';
import CloseIcon from 'components/icons/CloseIcon';

import handleEvent from 'utilities/handleEvent';

import styles from './styles.module.scss';

const ModalWindow = ({
    backButtonClicked,
    backButtonDisabled,
    backButtonLabel = 'Back',
    children,
    closeIconClassName = '',
    contentClassName = '',
    footerButtonClassName = '',
    footerButtonClicked,
    footerButtonDisabled,
    footerContainerClassName,
    footerClassName,
    footerId,
    footerLabel = 'Close',
    footerSubContent,
    headerClassName = '',
    headerHeadingClassName = '',
    headerIcon,
    headerIconAlt = '',
    headerIconClassName,
    headerText,
    headerTextClassName = '',
    headerTitle = '',
    hideFooter = false,
    isBack = false,
    isFooterButtonEnable = true,
    isSkip = false,
    modalBoxClassName = '',
    modalHeaderTitle,
    modalWindow = styles.modalWindow,
    onClose,
    skipButtonClassName = '',
    skipButtonClicked,
    skipButtonDisabled,
    skipButtonLabel = 'Skip',
    isCloseButton = false
}) => {
    const ESCAPE = 27;
    const ENTER = 13;
    const SPACE = 32;

    const reEnableScroll = useCallback(() => {
        document.body.classList.remove(styles.disableScroll);
    }, []);

    const handleClose = useCallback(
        () => handleEvent(onClose, reEnableScroll),
        [onClose, reEnableScroll]
    )();

    const handleKeyDown = useCallback(
        event => {
            event.preventDefault();

            if (
                event.keyCode === ENTER ||
                event.keyCode === ESCAPE ||
                event.keyCode === SPACE
            ) {
                handleClose(event);
            }
        },
        [handleClose]
    );

    useEffect(() => {
        document.body.classList.add(styles.disableScroll);

        return reEnableScroll;
    }, [reEnableScroll]);

    return (
        <div className={`${modalWindow} ${modalBoxClassName}`}>
            <div className={`${headerClassName} ${styles.header}`}>
                <ListItem className={styles.listItem}>
                    <div className={styles.closeIcon}>
                        <CloseIcon
                            className={`${closeIconClassName} ${styles.closeIconClassName}`}
                            onClick={handleClose}
                            onKeyDown={handleKeyDown}
                            tabIndex="0"
                        />
                    </div>

                    <ModalHeader
                        headerHeadingClassName={headerHeadingClassName}
                        headerIconClassName={headerIconClassName}
                        headerTextClassName={headerTextClassName}
                        icon={headerIcon}
                        iconAlt={headerIconAlt}
                        modalHeaderTitle={modalHeaderTitle}
                        text={headerText}
                        title={headerTitle}
                    />
                </ListItem>
            </div>

            <div
                className={`${contentClassName} ${styles.content} ${
                    hideFooter ? styles.contentBorder : ''
                } modalContent`}
            >
                {children}
            </div>

            {/* {hideFooter ? null : footerBody()} */}
            {hideFooter ? null : (
                <ModalFooter
                    backButtonClicked={backButtonClicked}
                    backButtonDisabled={backButtonDisabled}
                    backButtonLabel={backButtonLabel}
                    footerButtonClassName={footerButtonClassName}
                    footerContainerClassName={footerContainerClassName}
                    footerButtonClicked={footerButtonClicked}
                    footerButtonDisabled={footerButtonDisabled}
                    footerClassName={footerClassName}
                    footerId={footerId}
                    footerLabel={footerLabel}
                    footerSubContent={footerSubContent}
                    isBack={isBack}
                    isFooterButtonEnable={isFooterButtonEnable}
                    isSkip={isSkip}
                    skipButtonClassName={skipButtonClassName}
                    skipButtonClicked={skipButtonClicked}
                    skipButtonDisabled={skipButtonDisabled}
                    skipButtonLabel={skipButtonLabel}
                    isCloseButton={isCloseButton}
                    closeButtonClicked={handleClose}
                />
            )}
        </div>
    );
};

export default ModalWindow;
