const COMPARE_PLANS = 'PlanEnroll.api.comparePlans';
const CONTACT_CALLBACK_KEY = 'PlanEnroll.api.contactCallback';
const COUNTIES_KEY = 'PlanEnroll.api.counties';
const COUNTY_FIPS_KEY = 'PlanEnroll.api.countyFips';
const COVERAGE_AMOUNT = 'PlanEnroll.api.coverageAmount';
const DOCTOR_ADDRESS_PAGE_KEY = 'PlanEnroll.api.doctorAddressPage';
const DOCTOR_DETAILS_KEY = 'PlanEnroll.api.doctorDetails';
const DOCTOR_NAMES_KEY = 'PlanEnroll.api.doctorNames';
const DOCTOR_SEARCH_TERM_KEY = 'PlanEnroll.api.doctorSearchTerm';
const DRUG_NAMES_KEY = 'PlanEnroll.api.drugNames';
const ENROLL_MODAL_INPUT_DATA = 'PlanEnroll.api.enrollModalInputData';
const FINALEXPENSE_AGENT_DATA = 'PlanEnroll.api.finalExpenseAgentData';
const FINALEXPENSE_AGENT_NPN = 'PlanEnroll.api.finalExpenseAgentNpn';
const FINAL_EXPENSE_KEY = 'PlanEnroll.api.finalExpense';
const FINAL_EXPENSE_STEPS = 'PlanEnroll.api.finalExpenseSteps';
const FROM_EMAIL = 'PlanEnroll.api.fromEmail';
const HEALTH_CONDITION_DETAILS_KEY = 'PlanEnroll.api.healthConditionDetails';
const QUICK_PROFILE_PERSONAL_INFORMATION =
    'PlanEnroll.api.quickProfilePersonalInformation';
const QUICK_PROFILE_LEAD_INFORMATION = 'PlanEnroll.api.quickProfileLeadInfo';
const QUICK_PROFILE_COVERAGE = 'PlanEnroll.api.quickProfileCoverage';
const QUICK_PROFILE_COVERAGE_RES = 'PlanEnroll.api.quickProfileCoverageRes';
const HEALTH_CONDITION_INDEX_KEY = 'PlanEnroll.api.healthConditionIndex';
const HEALTH_CONDITION_OPERATION_KEY =
    'PlanEnroll.api.healthConditionOperation';
const HEALTH_CONDITION_SEARCH_TERM_KEY =
    'PlanEnroll.api.healthConditionSearchTerm';
const HEALTH_CONDITION_TERM_KEY = 'PlanEnroll.api.healthConditionTerm';
const HEALTH_CONDITION_NO_HEALTH_CONDITION =
    'PlanEnroll.api.HealthConditionNoHealthCondition';
const IS_NONRTS_USER = 'PlanEnroll.api.isNonRtsUser';
const LEAD_ID_KEY = 'PlanEnroll.api.leadId';
const MEDICAID_KEY = 'PlanEnroll.api.medicaid';
const MEDICARE_AGENT_DATA = 'PlanEnroll.api.medicareAgentData';
const MEDICARE_AGENT_NPN = 'PlanEnroll.api.medicareAgentNpn';
const MEDICARE_STEPS = 'PlanEnroll.api.medicareSteps';
const MONTHLY_PREMIUM_MIN = 'PlanEnroll.api.monthlyPreminumMin';
const PHARMACY_ADDRESS_PAGE_KEY = 'PlanEnroll.api.doctorAddressPage';
const PHARMACY_DETAILS_KEY = 'PlanEnroll.api.doctorDetails';
const PHARMACY_LIST_PAGE = 'PlanEnroll.api.pharmacyListPage';
const PHARMACY_NAMES_KEY = 'PlanEnroll.api.pharmacyNames';
const PHARMACY_SEARCH_TERM_KEY = 'PlanEnroll.api.doctorSearchTerm';
const PLAN_DATE_KEY = 'PlanEnroll.api.planDateKey';
const PLAN_DETAIL_DATE_KEY = 'PlanEnroll.api.planDetailDateKey';
const PLAN_TYPE_KEY = 'PlanEnroll.api.planType';
const PLAN_YEAR_KEY = 'PlanEnroll.api.planYearKey';
const PRESCRIPTION_SEARCH_TERM_KEY = 'PlanEnroll.api.prescriptionSearchTerm';
const PRESCRIPTION_NO_PRESCRIPTIONS =
    'PlanEnroll.api.prescriptionNoPrescription';
const RADIUS_MILES_KEY = 'PlanEnroll.api.radiusMiles';
const REQUEST_CALLBACK_KEY = 'PlanEnroll.api.requestCallback';
const SELECTED_AGENT_TYPE = 'PlanEnroll.api.selectedAgentType';
const SELECTED_PLAN_TYPE = 'PlanEnroll.api.selectedPlanType';
const STATE_KEY = 'PlanEnroll.api.state';
const USER_PROFILE_PIC = 'PlanEnroll.api.userProfilePic';
const ZIP_CODE_KEY = 'PlanEnroll.api.zipCode';
const UTM_SOURCE = 'utm_source';
const UTM_MEDIUM = 'utm_medium';
const UTM_CAMPAIGN = 'utm_campaign';
const UTM_CONTENT = 'utm_content';
const UTM_TERM = 'utm_term';
const GCLID = 'gclid';
const QUICK_PROFILE_STEPS = 'PlanEnroll.api.quickProfileSteps';
const REDIRECT_PHARMACY = 'PlanEnroll.api.redirectPharmacy';

export {
    COMPARE_PLANS,
    CONTACT_CALLBACK_KEY,
    COUNTIES_KEY,
    COUNTY_FIPS_KEY,
    COVERAGE_AMOUNT,
    DOCTOR_ADDRESS_PAGE_KEY,
    DOCTOR_DETAILS_KEY,
    DOCTOR_NAMES_KEY,
    DOCTOR_SEARCH_TERM_KEY,
    DRUG_NAMES_KEY,
    ENROLL_MODAL_INPUT_DATA,
    FINALEXPENSE_AGENT_DATA,
    FINALEXPENSE_AGENT_NPN,
    FINAL_EXPENSE_KEY,
    FINAL_EXPENSE_STEPS,
    QUICK_PROFILE_PERSONAL_INFORMATION,
    QUICK_PROFILE_COVERAGE,
    QUICK_PROFILE_COVERAGE_RES,
    FROM_EMAIL,
    GCLID,
    HEALTH_CONDITION_DETAILS_KEY,
    HEALTH_CONDITION_INDEX_KEY,
    HEALTH_CONDITION_NO_HEALTH_CONDITION,
    HEALTH_CONDITION_OPERATION_KEY,
    HEALTH_CONDITION_SEARCH_TERM_KEY,
    HEALTH_CONDITION_TERM_KEY,
    IS_NONRTS_USER,
    LEAD_ID_KEY,
    MEDICAID_KEY,
    MEDICARE_AGENT_DATA,
    MEDICARE_AGENT_NPN,
    MEDICARE_STEPS,
    MONTHLY_PREMIUM_MIN,
    PHARMACY_ADDRESS_PAGE_KEY,
    PHARMACY_DETAILS_KEY,
    PHARMACY_LIST_PAGE,
    PHARMACY_NAMES_KEY,
    PHARMACY_SEARCH_TERM_KEY,
    PLAN_DATE_KEY,
    PLAN_DETAIL_DATE_KEY,
    PLAN_TYPE_KEY,
    PLAN_YEAR_KEY,
    PRESCRIPTION_SEARCH_TERM_KEY,
    PRESCRIPTION_NO_PRESCRIPTIONS,
    RADIUS_MILES_KEY,
    REDIRECT_PHARMACY,
    REQUEST_CALLBACK_KEY,
    SELECTED_AGENT_TYPE,
    SELECTED_PLAN_TYPE,
    STATE_KEY,
    USER_PROFILE_PIC,
    ZIP_CODE_KEY,
    UTM_CAMPAIGN,
    UTM_CONTENT,
    UTM_MEDIUM,
    UTM_SOURCE,
    UTM_TERM,
    QUICK_PROFILE_STEPS,
    QUICK_PROFILE_LEAD_INFORMATION
};
