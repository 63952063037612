import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import Body2 from 'components/Body2';
import Body3 from 'components/Body3';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Phone from 'components/Phone';
import { setSelectedItem } from 'components/PlanTypeModal/slice';
import { usePurlBanner } from 'components/PurlBanner/usePurlBanner';
import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';
import Text from 'components/Text';
import useIntersectionObserver from 'components/hooks/useIntersectionObserver';

import phoneContext from 'contexts/phone';

import formatPhoneNumber from 'utilities/formatPhoneNumber';
import {
    CONTACT_AN_AGENT_ROUTE,
    FINAL_EXPENSE_AGENT,
    FINAL_EXPENSE_REQUEST_CALL_BACK,
    PLANS_ROUTE,
    REQUEST_CALL_BACK
} from 'utilities/routes';
import {
    CONTACT_CALLBACK_KEY,
    REQUEST_CALLBACK_KEY
} from 'utilities/storageKeys';

import close from './close.svg';
import crossCircle from './crossCircle.svg';
import image from './image.svg';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';

const INTERSECTION_RATIO_PLANS_SIDE_HEADER = 0.5;

const AgentHelp = () => {
    const { isAuthenticated } = useAuth0();
    const selectedPlanType = useSelector(
        state => state.planTypeDetail.selectedPlanType
    );
    const medicareAgent = useSelector(
        state => state.planTypeDetail.medicareAgent
    );
    const finalExpenseAgent = useSelector(
        state => state.planTypeDetail.finalExpenseAgent
    );
    const purlAgentData =
        selectedPlanType === 'Medicare' ? medicareAgent : finalExpenseAgent;
    const { HasMedicareAgentData, HasFinalExpenseAgentData } = usePurlBanner();
    const { agentFirstName, virtualNumber } = purlAgentData || {};
    const { phone } = useContext(phoneContext);
    const { text = '', number } = phone;

    const history = useHistory();
    const dispatch = useDispatch();

    const [isTextOpen, setIsTextOpen] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const isPlansPage = window.location.pathname === PLANS_ROUTE;
    const { intersectionRatio } = useIntersectionObserver({
        root: null,
        threshold: INTERSECTION_RATIO_PLANS_SIDE_HEADER,
        target: isPlansPage ? '#plansHeader' : null
    });
    const isPlansHeaderVisible =
        intersectionRatio <= INTERSECTION_RATIO_PLANS_SIDE_HEADER;

    const phoneNumber = purlAgentData
        ? formatPhoneNumber(virtualNumber?.substring(2))
        : text;
    const phoneNum = purlAgentData
        ? '1-' + formatPhoneNumber(virtualNumber?.substring(2))
        : number;

    const handleClose = () => {
        setIsTextOpen(false);
    };

    const handleToggleExpand = () => {
        handleClose();
        setIsExpanded(val => !val);
    };

    const handleReset = () => {
        setIsTextOpen(true);
        setIsExpanded(false);
    };

    useEffect(() => {
        if (isPlansHeaderVisible) {
            setIsExpanded(false);
        }
    }, [isPlansHeaderVisible]);

    const handleReachAgent = () => {
        if (purlAgentData) {
            set(REQUEST_CALLBACK_KEY, 'true');
            history.push(
                selectedPlanType === 'Medicare'
                    ? REQUEST_CALL_BACK
                    : FINAL_EXPENSE_REQUEST_CALL_BACK
            );
        } else {
            const disableFinalExpensePlanType = HasFinalExpenseAgentData();
            const disableMedicarePlanType = HasMedicareAgentData();
            const currentPage = window.location.href.substring(
                window.location.href.indexOf('#') + 1
            );

            if (
                window.location.href.includes('quick-profile') &&
                currentPage !== 'personalInformation' &&
                currentPage !== 'complete' &&
                window.location.hash &&
                !isAuthenticated
            ) {
                dispatch(setTargetPage('Help'));
                dispatch(
                    setSelectedQuickProfileItem({
                        title: 'Find an Agent',
                        navigationLink: CONTACT_AN_AGENT_ROUTE,
                        medicareLink: CONTACT_AN_AGENT_ROUTE,
                        finalExpenseLink: FINAL_EXPENSE_AGENT,
                        disableFinalExpensePlanType,
                        disableMedicarePlanType
                    })
                );
            } else {
                set(CONTACT_CALLBACK_KEY, 'true');
                dispatch(
                    setSelectedItem({
                        title: 'Find an Agent',
                        navigationLink: CONTACT_AN_AGENT_ROUTE,
                        medicareLink: CONTACT_AN_AGENT_ROUTE,
                        finalExpenseLink: FINAL_EXPENSE_AGENT,
                        disableFinalExpensePlanType,
                        disableMedicarePlanType
                    })
                );
            }
        }
    };

    return (
        <>
            <div
                className={`${styles.helpDescription} ${
                    isExpanded ? styles.helpDescriptionIsExpanded : ''
                }`}
            >
                <div className={styles.helpDescriptionHeader}>
                    <Body2
                        text="Need help making a decision?"
                        onClick={handleReset}
                        className={`${styles.blueColor} ${styles.headerHelpText}`}
                    />

                    <Icon
                        alt="White and blue message boxes"
                        className={styles.helpDescriptionIcon}
                        image={image}
                        onClick={handleToggleExpand}
                    />
                </div>

                <div className={styles.helpDescriptionBody}>
                    <Text
                        text="Get personalized guidance"
                        className={styles.bodyHeading}
                    />

                    <Body2
                        text="Connect with a licensed insurance agent."
                        className={styles.bodyText}
                    />

                    <Phone
                        className={styles.phoneHeading}
                        number={phoneNum}
                        text={phoneNumber}
                    />

                    <Body2
                        text="Or have an agent contact you."
                        className={styles.bodyFooterText}
                    />
                    <Button
                        label={
                            purlAgentData
                                ? `Reach ${agentFirstName}`
                                : 'Contact an Agent'
                        }
                        className={styles.reachButton}
                        onClick={handleReachAgent}
                    />
                </div>
            </div>
            <div className={`${styles.agentHelp}`}>
                <div
                    className={`${styles.contentIconContainer} ${
                        isTextOpen ? '' : styles.contentIconContainerClosed
                    }`}
                >
                    <Icon
                        alt="An X with a blue circle around it"
                        className={styles.crossIcon}
                        image={close}
                        onClick={handleClose}
                    />
                    <div
                        className={styles.contentContainer}
                        onClick={handleToggleExpand}
                    >
                        <Body3 text="Need help making a decision?" />
                    </div>
                </div>
                {!isExpanded && (
                    <Icon
                        alt="White and blue message boxes"
                        className={styles.image}
                        image={image}
                        onClick={handleToggleExpand}
                    />
                )}
                <div
                    className={`${styles.crossCircleContainer} ${
                        isExpanded ? styles.crossCircleContainerVisible : ''
                    }`}
                >
                    <Icon
                        alt="An X with a blue circle around it"
                        image={crossCircle}
                        className={`${styles.crossCircle} ${
                            isExpanded ? styles.crossCircleVisible : ''
                        }`}
                        onClick={handleToggleExpand}
                    />
                </div>
            </div>
        </>
    );
};

export default AgentHelp;
