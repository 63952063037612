import { useCallback, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

import ActionText from 'components/ActionText';
import Body2 from 'components/Body2';
import Heading5 from 'components/Heading5';
import IconWithLink from 'components/IconWithLink';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextAsLink from 'components/TextAsLink';

import handleEvent from 'utilities/handleEvent';
import isFunction from 'utilities/isFunction';

import arrow from './image/arrow.svg';

import styles from './styles.module.scss';

import useDebounce from 'temp/utilities/useDebounce';

// const PER_PAGE = 10;

const TypeAheadPharmacy = ({
    debounceDelay = 250,
    defaultValue,
    fetchResults,
    handleSelectedPharmacy,
    multipleFoundLabel = '',
    noResultsMessage = 'No results found under the current search criteria.',
    onChange,
    onClear,
    PER_PAGE = 10,
    placeholder = 'Start typing to search',
    radiusMiles,
    zipCode,
    county,
    resultMap,
    singleFoundLabel = '',
    startPrompt = 'Start typing to search.',
    title
}) => {
    const [showResults, setShowResults] = useState(false);
    const [total, setTotal] = useState(0);
    const [pharmacyList, setPharmacyList] = useState([]);
    const [searchTerm, setSearchTerm] = useState(defaultValue);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);

    const label =
        pharmacyList.length === 1 ? singleFoundLabel : multipleFoundLabel;
    const text1 = `${pharmacyList.length || 0} ${label} `;
    const text = `found`;
    const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay);
    const showNoResultsMessage =
        debouncedSearchTerm && pharmacyList?.length === 0;
    const infoMessage = total ? showNoResultsMessage : noResultsMessage;

    const searchTermChanged = e => {
        setTotal(0);
        setPage(1);
        const newSearchTerm = e?.target?.value;
        setSearchTerm(newSearchTerm);
        setShowResults(false);
        isFunction(onChange) && onChange(newSearchTerm);
    };

    const handleClear = handleEvent(onClear, () => {
        setSearchTerm('');
        setShowResults(false);
        handleSelectedPharmacy(null);
    });

    const clearResults = useCallback(() => {
        setPharmacyList([]);
        setTotal(0);
        setPage(1);
        setShowResults(false);
        handleSelectedPharmacy(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = useCallback(
        async ({
            searchTerm,
            signal,
            page,
            radiusMiles,
            zipCode,
            county,
            perPage
        }) => {
            setLoading(true);
            setShowResults(false);
            setError(false);

            try {
                const searchResults = await fetchResults({
                    searchTerm,
                    signal,
                    page,
                    zipCode,
                    radiusMiles,
                    county,
                    perPage
                });

                setError(false);
                if (searchResults?.pharmacyList) {
                    const results = searchResults?.pharmacyList
                        ? searchResults?.pharmacyList
                        : [];

                    if (page > 1) {
                        setPharmacyList(previousData => [
                            ...previousData,
                            ...results
                        ]);
                    } else {
                        setPharmacyList(results);
                    }
                    setTotal(searchResults.totalCount);
                } else {
                    clearResults();
                }
            } catch (networkError) {
                clearResults();
                setError(true);
            }

            setLoading(false);
            setShowResults(true);
        },
        [clearResults, fetchResults]
    );

    useEffect(() => {
        if (showResults && pharmacyList?.length > PER_PAGE) {
            let index = pharmacyList?.length - PER_PAGE;
            let element = document.getElementById(
                `pharmacy-searchItem-${index}`
            );
            element?.scrollIntoView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showResults]);

    useEffect(() => {
        setPage(1);
        setShowResults(false);
    }, [radiusMiles, zipCode, county]);

    useEffect(() => {
        document.getElementById(`pharmacy-searchItem-${page * PER_PAGE}`) &&
            document
                .getElementById(`pharmacy-searchItem-${page * PER_PAGE}`)
                .scrollIntoView();
    }, [page]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        getData({
            searchTerm: debouncedSearchTerm,
            signal,
            page,
            radiusMiles,
            zipCode,
            county,
            perPage: PER_PAGE
        });

        return () => {
            controller.abort();
        };
        // this effect should only be rerun when the debounced Search Term changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, page, radiusMiles, zipCode, county]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getData({
            county,
            page,
            perPage: PER_PAGE,
            radiusMiles,
            signal,
            zipCode
        });
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Body2 className={styles.title} text={title} />

            <TextField
                autoFocus
                className={styles.textField}
                onChange={searchTermChanged}
                defaultValue={defaultValue}
                error={error}
                variant="outlined"
                inputProps={{
                    maxLength: 100,
                    type: 'search'
                }}
                InputProps={{
                    endAdornment: (
                        <SearchOutlined className={styles.searchIcon} />
                    )
                }}
                placeholder={placeholder}
                value={searchTerm}
                id="pharmacySearchInput"
            />

            {searchTerm && (
                <ActionText
                    className={styles.actionText}
                    onClick={handleClear}
                    id="cancelSearchButton"
                >
                    Cancel
                    <span className={styles.hideOnMobile}>Search</span>
                </ActionText>
            )}

            <div className={styles.foundText} id="foundPharmaciesCount">
                <Heading5 className={styles.heading5} text={text1} />

                <Text
                    className={`${styles.text} ${styles.textItalic}`}
                    text={text}
                />
            </div>

            {loading ? <Spinner /> : null}

            {!loading && infoMessage && (
                <Text className={styles.infoMessage} text={infoMessage} />
            )}

            {!loading && showResults && (
                <ul className={styles.resultsList}>
                    {isFunction(resultMap) && pharmacyList?.map(resultMap)}
                </ul>
            )}
            {!loading && total > pharmacyList?.length && (
                <>
                    {total > PER_PAGE && (
                        <>
                            <div className={styles.divider} />
                            <IconWithLink
                                caption={'Show More'}
                                className={styles.iconWithLink}
                                iconClassName={styles.iconClassName}
                                image={arrow}
                                isImageClickable={true}
                                labelClassName={styles.labelClassName}
                                onClick={() => setPage(page + 1)}
                                isTextDisplayRight={false}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default TypeAheadPharmacy;
