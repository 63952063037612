import styles from './styles.module.scss';

const Body2Bold = ({
    className = '',
    htmlFor = '',
    text = '',
    id = 'label'
}) => (
    <span
        htmlFor={htmlFor}
        className={`${className} ${styles.body2Bold}`}
        id={id}
    >
        {text}
    </span>
);

export default Body2Bold;
