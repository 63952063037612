import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';

import { medicareEnrollLogoWhiteBackroundWAlt } from 'utilities/altText';

import image from './image.svg';

import styles from './styles.module.scss';

const Logo = ({ className = '' }) => {
    const { isAuthenticated } = useAuth0();
    const history = useHistory();
    const dispatch = useDispatch();
    const currentPage = window.location.href.substring(
        window.location.href.indexOf('#') + 1
    );
    return (
        <Link
            className={`${styles.logo} ${className}`}
            data-click-type="link"
            onClick={e => {
                e.preventDefault();

                if (
                    window.location.href.includes('quick-profile') &&
                    currentPage !== 'personalInformation' &&
                    currentPage !== 'complete' &&
                    window.location.hash &&
                    !isAuthenticated
                ) {
                    dispatch(setTargetPage('Logo'));
                    dispatch(setSelectedQuickProfileItem('/'));
                } else history.push('/');
            }}
        >
            <img
                alt={medicareEnrollLogoWhiteBackroundWAlt}
                className={`${styles.logo} ${className}`}
                src={image}
                id="logo"
            />
        </Link>
    );
};

export default Logo;
