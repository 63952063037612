import { setLocationModal } from '../YourLocationModal/slice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import {
    setGuidedProcess,
    setGuidedModal,
    previousMedicareStep,
    skipMedicareCurrentStep,
    resetStepperState,
    completeMedicareCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import CreateAccountBanner from 'components/CreateAccountBanner';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import { setDoctorModal } from 'components/YourDoctorModal/slice';
import YourPharmacyModalContent from 'components/YourPharmacyModalContent';

import {
    addPharmacy,
    deletePharmacy,
    getPharmacies
} from 'utilities/apiSession/pharmacies';
import dataLayer from 'utilities/dataLayer';
import {
    PHARMACY_DETAILS_KEY,
    PHARMACY_SEARCH_TERM_KEY,
    PHARMACY_NAMES_KEY,
    PHARMACY_LIST_PAGE
} from 'utilities/storageKeys';

import image from './image.svg';

import {
    addAndContinue,
    buttonLabel,
    headerText,
    headerTitle,
    plansButtonLabel
} from './constants';
import {
    setPharmacyModal,
    setPharmacyNames,
    setSelectedPharmacyId,
    setSelectedPlan
} from './slice';

import styles from './styles.module.scss';

import { checkModalDataHasChanged } from 'temp/utilities/modalHelper';
import { get, set } from 'temp/utilities/storage';

const YourPharmacyModal = ({ onChange = () => null }) => {
    const { user, getAccessTokenSilently } = useAuth0();
    const pharmacyModal = useSelector(
        state => state.pharmacyDetails.pharmacyModal
    );
    const initialPharmacyNames = useSelector(
        state => state.pharmacyDetails.pharmacyNames
    );

    const [footerButtonDisabled, setFooterButtonDisabled] = useState(false);

    useEffect(() => {
        if (pharmacyModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [pharmacyModal]);

    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);
    const syncId = useSelector(state => state.userProfile.userProfile.syncId);

    const dispatch = useDispatch();

    const [pharmacyName, setPharmacyName] = useState(
        get(PHARMACY_SEARCH_TERM_KEY, '')
    );
    const [loading, setLoading] = useState(false);
    const [pharmacyList, setPharmacyList] = useState([]);
    const [pharmacyListPage, setPharmacyListPage] = useState(
        get(PHARMACY_LIST_PAGE, false)
    );
    const [pharmacyDetails, setPharmacyDetails] = useState(
        get(PHARMACY_DETAILS_KEY, null)
    );
    const [selectedPharmacy, setSelectedPharmacy] = useState(null);

    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const handleSelectedPharmacy = data => {
        setSelectedPharmacy(data);
        dispatch(setSelectedPharmacyId(data?.pharmacyID));
    };

    const handlePharmacy = async data => {
        setLoading(true);
        setPharmacyListPage(true);
        set(PHARMACY_LIST_PAGE, true);
        setPharmacyModal(false);
        setPharmacyDetails(data);
        set(PHARMACY_DETAILS_KEY, data);
        await addPharmacy({
            data,
            syncId,
            authToken: await getAuthToken()
        });
        await getPharmaciesList();
        setLoading(false);
    };

    const searchTermChanged = searchTerm => {
        set(PHARMACY_SEARCH_TERM_KEY, searchTerm);
        setPharmacyName(searchTerm);
    };

    const handleClear = e => {
        searchTermChanged('');
        setSelectedPharmacy(null);
        dispatch(setSelectedPharmacyId(''));
    };

    const getPharmaciesList = useCallback(async () => {
        const authToken = await getAuthToken();
        const pharmacies = await getPharmacies(authToken);
        setPharmacyList(pharmacies || []);
    }, []);

    useEffect(() => {
        if (pharmacyModal) {
            getPharmaciesList();
        }
    }, [pharmacyModal, getPharmaciesList]);

    const handleDeletePharmacy = async pharmacyRecordID => {
        setPharmacyListPage(false);
        set(PHARMACY_LIST_PAGE, false);
        handleClear();
        set(PHARMACY_NAMES_KEY, []);
        setPharmacyNames([]);
        setPharmacyListPage(false);
        await deletePharmacy({
            id: pharmacyRecordID,
            syncId,
            authToken: await getAuthToken()
        });
        await getPharmaciesList();
    };

    const onClose = isContinue => {
        dispatch(setPharmacyModal(false));
        localStorage.removeItem('healthConditionFromPage');
        if (!isContinue) {
            dispatch(setGuidedProcess(false));
            dispatch(resetStepperState());
        } else {
            isGuided && dispatch(setDoctorModal(true));
            if (isGuided)
                pharmacyList.length > 0
                    ? dispatch(completeMedicareCurrentStep())
                    : dispatch(skipMedicareCurrentStep());
        }
        const pharmacyNames = pharmacyList?.map(item => item.name) || [];
        onChange();

        if (checkModalDataHasChanged(initialPharmacyNames, pharmacyNames)) {
            dispatch(setPharmacyNames(pharmacyNames || []));
        }
        dispatch(setSelectedPlan(null));
        dispatch(setSelectedPharmacyId(''));
        set(PHARMACY_NAMES_KEY, pharmacyNames);
    };

    const handleBackButton = () => {
        dispatch(setLocationModal(true));
        dispatch(setPharmacyModal(false));
        dispatch(previousMedicareStep());
    };

    const handleSkipButton = () => {
        dispatch(setGuidedModal(false));
        dispatch(setPharmacyModal(false));
        dispatch(setDoctorModal(true));
        const pharmacyNames = pharmacyList?.map(item => item.name);
        onChange();
        if (pharmacyNames?.length) {
            dispatch(setPharmacyNames(pharmacyNames));
        } else if (
            initialPharmacyNames?.length !== 0 &&
            pharmacyNames?.length === 0
        ) {
            dispatch(setPharmacyNames([]));
        }
        set(PHARMACY_NAMES_KEY, pharmacyNames);
        pharmacyList.length > 0
            ? dispatch(completeMedicareCurrentStep())
            : dispatch(skipMedicareCurrentStep());
    };

    const getFooterText = useCallback(() => {
        if (isGuided && !pharmacyListPage) return addAndContinue;
        if (isGuided && pharmacyListPage) return plansButtonLabel;
        if (!pharmacyListPage) return buttonLabel;
        return plansButtonLabel;
    }, [pharmacyListPage, isGuided]);

    const handleFooterClick = async () => {
        if (!pharmacyListPage && selectedPharmacy) {
            setFooterButtonDisabled(true);
            await handlePharmacy(selectedPharmacy);
            setFooterButtonDisabled(false);
        } else onClose(true);
    };

    useEffect(() => {
        if (isGuided) {
            setPharmacyListPage(pharmacyList.length);
        }
    }, [isGuided, pharmacyList]);

    const isBack = useMemo(() => {
        const fromPage = localStorage.getItem('healthConditionFromPage');
        if (fromPage) return isGuided && fromPage ? false : true;
        else return isGuided;
    }, [isGuided, pharmacyModal]);

    return (
        <Modal className={styles.modal} isOpen={pharmacyModal}>
            <ModalWindow
                contentClassName={styles.contentClassName}
                footerClassName={styles.footerClassName}
                footerButtonDisabled={
                    footerButtonDisabled || isGuided
                        ? pharmacyListPage
                            ? pharmacyList?.length === 0
                            : !selectedPharmacy
                        : !selectedPharmacy
                }
                footerLabel={getFooterText()}
                headerIcon={image}
                headerText={headerText}
                headerTitle={headerTitle}
                headerClassName={styles.headerClassName}
                closeIconClassName={styles.closeIconClassName}
                footerButtonClassName={styles.footerButtonClassName}
                onClose={() => onClose()}
                footerButtonClicked={handleFooterClick}
                isSkip={isGuided}
                isBack={isBack}
                skipButtonClicked={handleSkipButton}
                backButtonClicked={handleBackButton}
                hideFooter={!isGuided && pharmacyListPage}
            >
                <div
                    className={styles.content}
                    data-testid="your-pharmacy-modal"
                >
                    <YourPharmacyModalContent
                        defaultValue={pharmacyName}
                        handleDeletePharmacy={handleDeletePharmacy}
                        handleSelectedPharmacy={handleSelectedPharmacy}
                        loading={loading}
                        onChange={searchTermChanged}
                        onClear={handleClear}
                        pharmacyDetails={pharmacyDetails}
                        pharmacyList={pharmacyList}
                        pharmacyListPage={pharmacyListPage}
                    />
                </div>
                {isGuided && false && <CreateAccountBanner />}
            </ModalWindow>
        </Modal>
    );
};

export default YourPharmacyModal;
