import { memo } from 'react';

import { Tooltip } from '@mui/material';

import CloseIcon from 'components/icons/CloseIcon';

import {
    PURL_AGENT_CODE,
    PURL_AGENT_CODE_NEW,
    PURL_AGENT_DATA
} from 'utilities/sessionStorageKeys';
import {
    FINALEXPENSE_AGENT_DATA,
    FINALEXPENSE_AGENT_NPN,
    MEDICARE_AGENT_DATA,
    MEDICARE_AGENT_NPN
} from 'utilities/storageKeys';

import styles from './styles.module.scss';

import { remove } from 'temp/utilities/storage';

const PurlBannerAgentExperience = () => {
    const handleClose = (isReturnToAgent = false) => {
        remove(PURL_AGENT_CODE_NEW);
        remove(PURL_AGENT_CODE);
        remove(PURL_AGENT_DATA);
        remove(MEDICARE_AGENT_DATA);
        remove(FINALEXPENSE_AGENT_DATA);
        remove(FINALEXPENSE_AGENT_NPN);
        remove(MEDICARE_AGENT_NPN);
        localStorage.removeItem('reqAgentData');
        window.location.href = isReturnToAgent
            ? `${import.meta.env.VITE_APP_APP_BASE_URL}/contact-an-agent`
            : `${import.meta.env.VITE_APP_APP_BASE_URL}`;
    };
    return localStorage.getItem('reqAgentData') ? (
        <Tooltip title="Remove Agent Experience" arrow>
            <div className={styles.closeIconDiv}>
                <CloseIcon
                    className={styles.closeIconClassName}
                    iconColor={'white'}
                    onClick={() => handleClose()}
                    tabIndex="0"
                />
            </div>
        </Tooltip>
    ) : (
        <></>
    );
};

export default memo(PurlBannerAgentExperience);
