import { useContext } from 'react';

import RadioMultipleOptions from 'components/RadioMultipleOptions';
import Text from 'components/Text';

import ZipCodeContext from 'contexts/zipCode';

import { MedicaidRadioOptions } from './constants';

import styles from './styles.module.scss';

const YourMedicaidModalContent = ({
    selectedMedicaidOption,
    setMedicaidSelection
}) => {
    const { state } = useContext(ZipCodeContext);

    const handleMedicaidBenefits = e =>
        setMedicaidSelection(e.currentTarget.value);

    return (
        <div className={styles.yourMedicaidModalContent}>
            <Text
                className={styles.text}
                text={`Are you receiving benefits from ${
                    state ?? ''
                } Medicaid?`}
                id="Medicaid_Benefits"
            />

            <RadioMultipleOptions
                radioClassName={styles.radioClassName}
                id="Medicaid_Benefits"
                items={MedicaidRadioOptions}
                name="Medicaid Benefits"
                onChange={handleMedicaidBenefits}
                selectedOption={selectedMedicaidOption}
            />
        </div>
    );
};

export default YourMedicaidModalContent;
