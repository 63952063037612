import { getAgentNpn } from './getSessionData';
import { PURL_AGENT_CODE } from './sessionStorageKeys';

import { get } from 'temp/utilities/storage';

const deleteJSON = async ({ body, url, authToken }) => {
    const agentNpn = getAgentNpn();
    const agentPurl = get(PURL_AGENT_CODE);
    const options = {
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            ...(agentPurl && { isPurl: true }),
            ...(agentNpn && { AgentNPN: agentNpn }),
            ...(authToken && {
                Authorization: `Bearer ${authToken}`
            })
        },
        method: 'DELETE'
    };

    const response = await fetch(url, options);

    return response || '';
};

export default deleteJSON;
