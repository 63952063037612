import PlansByZipForm from '../PlansByZipForm';
import moment from 'moment';
import React, {
    forwardRef,
    useContext,
    useImperativeHandle,
    useState,
    useMemo,
    useEffect,
    useRef
} from 'react';
import { useCallback } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setUserProfile } from 'services/clientServices/slice';

import { useAuth0 } from '@auth0/auth0-react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { MenuItem, Select } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';

import Button from 'components/Button';
import DatePickerWithLabel from 'components/DatePickerWithLabel';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import RadioWithLabel from 'components/RadioWithLabel';
import Text from 'components/Text';
import { setHealthInfoData } from 'components/YourHealthInfoModal/slice';
import useFetch from 'components/hooks/useFetch';

import zipcodeContext from 'contexts/zipCode';

import { CONSUMER_SERVICE_URL } from 'utilities/env';
import getHealthInfoTokenData from 'utilities/getHealthInfoTokenData';
import {
    FINAL_EXPENSE_CONTACT_DETAILS_ROUTE,
    FINAL_EXPENSE_HEALTH_CONDITION_ROUTE
} from 'utilities/routes';
import { FINAL_EXPENSE_KEY } from 'utilities/storageKeys';

import arrowIcon from './images/arrowIcon.svg';

import {
    GenderOptions,
    TobaccoOptions,
    dateOfBirthRegex,
    invalidFeetError,
    invalidInchError,
    invalidWeightError
} from './constants';

import styles, { mobileWidth } from './styles.module.scss';

import FinalExpenseHeaderNav from 'temp/components/FinalExpenseHeaderNav';
import { getMinAge } from 'temp/utilities/age';
import { get, set } from 'temp/utilities/storage';

const YourHealthInfoModalContent = forwardRef(
    (
        {
            setDateOfBirthVal,
            setGender,
            setHeightFeet,
            setTobacco,
            setHeightInch,
            setWeightVal,
            isModal,
            setDateOfBirthError,
            setHeightInchError,
            setHeightFeetError,
            setWeightValError
        },
        ref
    ) => {
        localStorage.removeItem('healthConditionFromPage');
        const history = useHistory();
        const tokenData = getHealthInfoTokenData();
        localStorage.removeItem('HealthInfoDataChanged');
        const [finalExpenseDetails] = useState(get(FINAL_EXPENSE_KEY));
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isMobile, setIsMobile] = useState(false);
        const [selectedGender, setSelectedGender] = useState(
            tokenData?.gender
                ? tokenData.gender === 'M'
                    ? 'Male'
                    : 'Female'
                : finalExpenseDetails?.gender
        );
        const [selectedTobacco, setSelectedTobacco] = useState(
            tokenData.use_tobacco
                ? tokenData.use_tobacco === 'false'
                    ? 'No'
                    : 'Yes'
                : finalExpenseDetails?.tobaccoUse || ''
        );
        const [feet, setFeet] = useState(finalExpenseDetails?.heightInFT || '');
        const [inch, setInch] = useState(finalExpenseDetails?.heightIn || '');
        const [weight, setWeight] = useState(finalExpenseDetails?.weight || '');
        const [tobaccoOption, setTobaccoOption] = useState(
            finalExpenseDetails?.tobaccoUse || ''
        );
        const [contactDetailsPage, setContactDetailsPage] = useState(false);

        const [isDisable, setIsDisable] = useState(true);
        const [dateError, setDateError] = useState(false);
        const [feetError, setFeetError] = useState(false);
        const [inchError, setInchError] = useState(false);
        const [weightError, setWeightError] = useState(false);
        const dispatch = useDispatch();
        const getBirthDate = age => {
            return moment()
                .subtract(age, 'years')
                .startOf('year')
                .format('DD/DD/YYYY');
        };
        const [dateOfBirth, setDateOfBirth] = useState(
            tokenData?.age
                ? getBirthDate(tokenData.age)
                : finalExpenseDetails?.dateOfBirth || ''
        );
        const { county, error, zipCode } = useContext(zipcodeContext);
        const isFirstLoad = useRef(true);
        const getAge = date => {
            const birthDate = moment(date);
            var today = new Date();
            var age_now = today.getFullYear() - birthDate.format('YYYY');
            var m = today.getMonth() + 1 - birthDate.format('MM');
            if (
                m < 0 ||
                (m === 0 && today.getDate() < birthDate.format('DD'))
            ) {
                age_now--;
            }
            return age_now;
        };
        const isValidDateDetails = date => {
            setDateError(false);
            if (dateOfBirthRegex.test(date)) {
                const age = getAge(date);
                if (age < getMinAge() || age > 85) {
                    setDateError(true);
                    return false;
                }
            } else {
                setDateError(true);
                return false;
            }

            return true;
        };

        const isValidInch = inchVal => {
            setInchError(false);

            if (inchVal < 0 || inchVal > 11) {
                setInchError(true);
                return false;
            }

            return true;
        };

        const isValidFeet = feetVal => {
            setFeetError(false);

            if (feetVal !== '' && (feetVal < 1 || feetVal > 8)) {
                setFeetError(true);
                return false;
            }

            return true;
        };

        const isValidWeight = weightVal => {
            setWeightError(false);

            if (weightVal !== '' && (weightVal < 10 || weightVal > 998)) {
                setWeightError(true);
                return false;
            }

            return true;
        };
        const isValid = useCallback(() => {
            if (
                zipCode?.trim() === '' ||
                zipCode?.trim().length !== 5 ||
                county?.trim() === '' ||
                selectedGender?.trim() === '' ||
                selectedTobacco?.trim() === '' ||
                dateOfBirth === '' ||
                dateError ||
                inchError ||
                feetError ||
                weightError
            )
                return false;

            return true;
        }, [
            zipCode,
            county,
            selectedGender,
            selectedTobacco,
            feet,
            inch,
            weight,
            dateOfBirth
        ]);
        const userProfile = useSelector(state => state.userProfile.userProfile);
        useEffect(() => {
            if (isFirstLoad.current) {
                window.location.pathname ===
                    FINAL_EXPENSE_CONTACT_DETAILS_ROUTE &&
                    setContactDetailsPage(true);
                const state = isValid();
                setIsDisable(!state);
                isFirstLoad.current = false;
            }
        }, [isFirstLoad, isValid]);
        useEffect(() => {
            if (isAuthenticated && user.consumerid) {
                const tobaccoUse = userProfile.isTobaccoUser
                    ? 'Yes'
                    : userProfile.isTobaccoUser === false
                    ? 'No'
                    : '';
                const dateVal = userProfile.dateOfBirth
                    ? moment(userProfile.dateOfBirth).format('MM/DD/YYYY')
                    : '';

                const feetVal = Math.floor(userProfile.height / 12);
                const inches = userProfile.height % 12;

                setSelectedGender(userProfile?.gender);
                setDateOfBirth(dateVal);
                setSelectedTobacco(tobaccoUse);
                setTobaccoOption(tobaccoUse);
                setWeight(userProfile.weight);
                setFeet(feetVal);
                setInch(inches);
                let payload = {};
                payload.gender = userProfile.gender;
                payload.heightInFT = feetVal;
                payload.heightIn = inches;
                payload.weight = userProfile.weight;
                payload.tobaccoUse = tobaccoUse;
                payload.zipCode = zipCode;
                payload.county = county;
                payload.dateOfBirth = dateVal;
                set(FINAL_EXPENSE_KEY, payload);
            }
        }, [userProfile]);
        useEffect(() => {
            isModal && setGender(selectedGender);
        }, [selectedGender, isModal, setGender]);
        useEffect(() => {
            isModal && setTobacco(selectedTobacco);
        }, [selectedTobacco, isModal, setTobacco]);
        useEffect(() => {
            isModal && setHeightFeet(feet);
        }, [feet, isModal, setHeightFeet]);
        useEffect(() => {
            isModal && setHeightInch(inch);
        }, [inch, isModal, setHeightInch]);
        useEffect(() => {
            isModal && setWeightVal(weight);
        }, [weight, isModal, setWeightVal]);
        useEffect(() => {
            isModal && setDateOfBirthVal(dateOfBirth);
        }, [dateOfBirth, isModal, setDateOfBirthVal]);
        useEffect(() => {
            isModal && setDateOfBirthError(dateError);
        }, [dateError, isModal, setDateOfBirthError]);
        useEffect(() => {
            isModal && setHeightInchError(inchError);
        }, [inchError, isModal, setHeightInchError]);
        useEffect(() => {
            isModal && setHeightFeetError(feetError);
        }, [feetError, isModal, setHeightFeetError]);
        useEffect(() => {
            isModal && setWeightValError(weightError);
        }, [weightError, isModal, setWeightValError]);

        const sorts = TobaccoOptions.map((option, index) => {
            return (
                <>
                    <RadioWithLabel
                        className={`${styles.radioButton} ${
                            index !== 0 &&
                            index !== TobaccoOptions.length &&
                            styles.tobaccoOptions
                        }`}
                        checked={tobaccoOption === option}
                        id={option}
                        key={option}
                        label={option}
                        name="tobacco"
                        onChange={() => setTobaccoOption(option)}
                        value={option}
                    />
                </>
            );
        });

        const options = useMemo(() => {
            const options = [];

            for (const TobaccoOption of TobaccoOptions) {
                options.push({
                    label: TobaccoOption,
                    value: TobaccoOption
                });
            }
            return options;
        }, [TobaccoOptions]);

        const { isAuthenticated, user } = useAuth0();
        const { Put: updateAccountData } = useFetch(
            `${import.meta.env.VITE_APP_ACCOUNT_SERVICE_URL}/Update`
        );
        const { Get: getConsumerByConsumerId } = useFetch(
            `${CONSUMER_SERVICE_URL}/${user?.consumerid}`
        );
        const onSubmitDetails = async () => {
            if (isValidDetails()) {
                let payload = {};
                payload.gender = selectedGender;
                payload.heightInFT = feet;
                payload.heightIn = inch;
                payload.weight = weight;
                payload.tobaccoUse = selectedTobacco;
                payload.zipCode = zipCode;
                payload.county = county;
                payload.dateOfBirth = dateOfBirth;
                set(FINAL_EXPENSE_KEY, payload);

                if (isAuthenticated && user.consumerid) {
                    const heightInch = Number(feet) * 12 + Number(inch);

                    await updateAccountData({
                        ...userProfile,
                        height: heightInch,
                        weight: weight,
                        dateOfBirth: new Date(dateOfBirth),
                        isTobaccoUser: selectedTobacco === 'Yes' ? true : false,
                        gender: selectedGender
                    });

                    const response = await getConsumerByConsumerId().catch(
                        error => console.error(error)
                    );

                    dispatch(
                        setUserProfile({
                            ...response
                        })
                    );
                }
                history.push(FINAL_EXPENSE_HEALTH_CONDITION_ROUTE);
                dispatch(setHealthInfoData(payload));
            }
        };
        const [open, setOpen] = useState(false);
        const onOpen = () => {
            setOpen(true);
        };
        const onClose = () => {
            setOpen(false);
        };
        const renderValue = value => (value ? value : 'Select');

        useEffect(() => {
            isMobile &&
                document
                    .getElementById('tobaccoUseSelect')
                    .setAttribute('readonly', 'true');
        }, [isMobile]);

        const isValidDetails = () => {
            let isValid = true;
            setDateError(false);
            setWeightError(false);
            setFeetError(false);
            setInchError(false);
            if (dateOfBirthRegex.test(dateOfBirth)) {
                const age = getAge(dateOfBirth);
                if (age < getMinAge() || age > 85) {
                    setDateError(true);
                    isValid = false;
                }
            } else {
                setDateError(true);
                return false;
            }

            if ((feet && feet < 1) || feet > 8) {
                setFeetError(true);
                isValid = false;
            }
            if ((inch && isNaN(inch)) || inch < 0 || inch > 11) {
                setInchError(true);
                isValid = false;
            }
            if ((weight && weight < 10) || weight > 998) {
                setWeightError(true);
                isValid = false;
            }

            return isValid;
        };

        useImperativeHandle(ref, () => ({
            isValidDetails
        }));

        useEffect(() => {
            const state = isValid();
            setIsDisable(!state || error);
        }, [
            selectedGender,
            selectedTobacco,
            feet,
            inch,
            weight,
            zipCode,
            dateOfBirth,
            error,
            county,
            isValid
        ]);

        return (
            <>
                {contactDetailsPage && (
                    <div>
                        <div className={styles.contactDetailsHeaderNav}>
                            <FinalExpenseHeaderNav from={'ContactDetails'} />
                        </div>
                    </div>
                )}
                <div
                    className={`${
                        contactDetailsPage && styles.contactDetailsPage
                    }`}
                >
                    <Media
                        queries={{
                            mobile: { maxWidth: mobileWidth }
                        }}
                        onChange={breakpoint => {
                            setIsMobile(breakpoint.mobile);
                        }}
                    />
                    {contactDetailsPage && (
                        <Text
                            className={styles.contactDetailsText}
                            text={'Contact Details'}
                            id="contactDetails"
                        />
                    )}

                    <div
                        className={`${
                            contactDetailsPage && styles.contactDetails
                        }`}
                    >
                        {contactDetailsPage && (
                            <>
                                <Text
                                    className={styles.confirm}
                                    text={'Let’s confirm a few details'}
                                    id="confirm"
                                />

                                <Text
                                    className={styles.questions}
                                    text={
                                        'Just a few quick and easy questions to get your quote.'
                                    }
                                    id="questions"
                                />

                                <div>
                                    <PlansByZipForm
                                        className={styles.zipCodeInput}
                                        id={'ContactDetails'}
                                        zipLabelClass={styles.text}
                                        countyLabelClass={styles.text}
                                        isOnChangeEnable={true}
                                        showSingleCounty={false}
                                    />
                                </div>
                            </>
                        )}

                        <div className={styles.gender}>
                            <Text
                                className={styles.text}
                                text={`Gender *`}
                                id="gender"
                            />

                            <div className={styles.genderOptions}>
                                {GenderOptions.map(option => (
                                    <Button
                                        label={option}
                                        className={`${
                                            option.toUpperCase() ===
                                            selectedGender?.toUpperCase()
                                                ? styles.activeButton
                                                : styles.inactiveButton
                                        } ${styles.button}`}
                                        onClick={() =>
                                            setSelectedGender(option)
                                        }
                                        id={option}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className={styles.dateOfBirth}>
                            <DatePickerWithLabel
                                defaultValue={dateOfBirth}
                                id="finalExpenseDateOfBirth"
                                labelText="Date of Birth *"
                                onChange={date => {
                                    setDateOfBirth(
                                        moment(date).format('MM/DD/YYYY')
                                    );
                                    isValidDateDetails(
                                        moment(date).format('MM/DD/YYYY')
                                    );
                                }}
                                labelClass={styles.text}
                            />

                            <Text className={styles.error} id="error">
                                {dateError &&
                                    `Please enter a valid date of birth(age must be between ${getMinAge()} to 85)`}
                            </Text>
                        </div>

                        <div className={styles.heightAndWeight}>
                            <div>
                                <Text
                                    className={styles.text}
                                    text={`Height`}
                                    id="heightLabel"
                                />
                                <div className={styles.height}>
                                    <TextField
                                        id="ftInput"
                                        className={styles.heightTextField}
                                        onChange={event => {
                                            setFeet(event.target.value);
                                            isValidFeet(event.target.value);
                                        }}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    className={styles.label}
                                                    position="end"
                                                >
                                                    ft
                                                </InputAdornment>
                                            )
                                        }}
                                        inputProps={{ maxLength: 1 }}
                                        placeholder={'Value'}
                                        value={feet || ''}
                                    />
                                    <TextField
                                        id="inInput"
                                        className={styles.heightTextField}
                                        onChange={event => {
                                            setInch(event.target.value);
                                            isValidInch(event.target.value);
                                        }}
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    className={styles.label}
                                                    position="end"
                                                >
                                                    in
                                                </InputAdornment>
                                            )
                                        }}
                                        inputProps={{ maxLength: 2 }}
                                        placeholder={'Value'}
                                        value={inch || ''}
                                    />
                                </div>
                                {isMobile && (
                                    <>
                                        <Text
                                            className={styles.error}
                                            id="feetError"
                                        >
                                            {feetError && invalidFeetError}
                                        </Text>
                                        <Text
                                            className={styles.error}
                                            id="inchError"
                                        >
                                            {inchError && invalidInchError}
                                        </Text>
                                    </>
                                )}
                            </div>
                            <div className={styles.weight}>
                                <Text
                                    className={styles.text}
                                    text={`Weight`}
                                    id="weightLabel"
                                />
                                <TextField
                                    id="weightInput"
                                    className={styles.weightTextField}
                                    onChange={event => {
                                        setWeight(event.target.value);
                                        isValidWeight(event.target.value);
                                    }}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                className={styles.label}
                                                position="end"
                                            >
                                                lbs
                                            </InputAdornment>
                                        )
                                    }}
                                    inputProps={{ maxLength: 3 }}
                                    placeholder={'Value'}
                                    value={weight || ''}
                                />
                            </div>
                        </div>
                        {!isMobile && (
                            <>
                                <Text className={styles.error} id="feetError">
                                    {feetError && invalidFeetError}
                                </Text>
                                <Text className={styles.error} id="inchError">
                                    {inchError && invalidInchError}
                                </Text>
                            </>
                        )}
                        <Text className={styles.error} id="weightError">
                            {weightError && invalidWeightError}
                        </Text>

                        <div className={styles.tobacco}>
                            <Text
                                className={styles.text}
                                text={`Tobacco Use *`}
                                id="tobaccoUseLabel"
                            />
                            {isMobile ? (
                                <TextField
                                    id={'tobaccoUseSelect'}
                                    autoFocus
                                    className={styles.tobaccoUseSelect}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 100,
                                        type: 'search'
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <KeyboardArrowDownRoundedIcon
                                                className={styles.searchIcon}
                                                onClick={() =>
                                                    setIsModalOpen(true)
                                                }
                                            />
                                        ),
                                        onClick: () => setIsModalOpen(true)
                                    }}
                                    placeholder={'Select'}
                                    value={selectedTobacco}
                                />
                            ) : (
                                <>
                                    <Select
                                        className={` ${styles.select}`}
                                        IconComponent={() => (
                                            <img
                                                alt="arrowIcon"
                                                className={` ${
                                                    styles.selectIconStyle
                                                } ${
                                                    open
                                                        ? styles.selectIconStyleActive
                                                        : ''
                                                }`}
                                                src={arrowIcon}
                                            />
                                        )}
                                        onChange={e =>
                                            setSelectedTobacco(e?.target?.value)
                                        }
                                        onClose={onClose}
                                        onOpen={onOpen}
                                        open={open}
                                        renderValue={renderValue}
                                        displayEmpty={true}
                                        value={selectedTobacco}
                                        variant="outlined"
                                        id={'tobaccoUseSelect'}
                                    >
                                        {Array.isArray(options) &&
                                            options.map(option => (
                                                <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                    id={option.label}
                                                >
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </>
                            )}
                        </div>
                    </div>

                    <Text
                        className={styles.requiredText}
                        text={`*Required fields`}
                        id="requiredFields"
                    />
                    {contactDetailsPage && (
                        <Button
                            label={'Continue'}
                            className={styles.continueButton}
                            disabled={isDisable}
                            onClick={() => onSubmitDetails()}
                        />
                    )}

                    <Modal className="filter-modal" isOpen={isModalOpen}>
                        <ModalWindow
                            contentClassName={styles.contentClassName}
                            headerClassName={styles.modalHeader}
                            footerButtonClassName={styles.footerButtonClassName}
                            footerButtonClicked={() => {
                                setSelectedTobacco(tobaccoOption);
                                setIsModalOpen(false);
                            }}
                            footerClassName={styles.footerClassName}
                            footerLabel={'Confirm'}
                            headerTitle={'Tobacco Use'}
                            modalWindow={styles.modalWindow}
                            onClose={() => setIsModalOpen(false)}
                        >
                            {sorts}
                        </ModalWindow>
                    </Modal>
                </div>
            </>
        );
    }
);

export default React.memo(YourHealthInfoModalContent);
