import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import DoctorListItem from 'components/DoctorListItem';
import TypeAheadDoctor from 'components/TypeAheadDoctor';
import { setIsApiFailed } from 'components/YourDoctorModal/slice';
import ZipRadiusSection from 'components/ZipRadiusSection';

import ZipCodeContext from 'contexts/zipCode';

import {
    multipleFoundLabel,
    noResultsMessage,
    placeholder,
    singleFoundLabel,
    startPrompt,
    typeAheadTitle
} from './constants';
import { doctorSearch } from './data';

const DoctorSearch = ({
    defaultValue,
    handleDoctor,
    onChange,
    onClear,
    addDoctorLoader,
    maxReached,
    PER_PAGE
}) => {
    const dispatch = useDispatch();
    const radiusMiles = useSelector(
        state => state.distanceDropdownDetails.radiusMiles
    );

    const { zipCode, county } = useContext(ZipCodeContext);
    const { user, getAccessTokenSilently } = useAuth0();
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    const resultMap = (data, index) => {
        const { firstName, lastName, specialty, NPI, addresses } = data;

        return (
            <DoctorListItem
                addresses={addresses}
                firstName={firstName}
                key={NPI}
                id={`doctor-searchItem-${index}`}
                lastName={lastName}
                onClick={address => handleDoctor(data, address)}
                specialty={specialty}
                maxReached={maxReached}
            />
        );
    };

    const fetchResults = async ({
        searchTerm,
        signal,
        page,
        radiusMiles,
        zipCode,
        county,
        perPage
    }) => {
        try {
            const authToken = await getAuthToken();
            const results = await doctorSearch({
                fips: county,
                page,
                perPage,
                radius: radiusMiles,
                searchTerm,
                signal,
                zipCode,
                authToken
            });

            dispatch(setIsApiFailed(false));
            return results;
        } catch (error) {
            dispatch(setIsApiFailed(true));
            return null;
        }
    };

    return (
        <>
            <ZipRadiusSection label="Doctor’s" />

            <TypeAheadDoctor
                defaultValue={defaultValue}
                fetchResults={fetchResults}
                multipleFoundLabel={multipleFoundLabel}
                noResultsMessage={noResultsMessage}
                onChange={onChange}
                onClear={onClear}
                placeholder={placeholder}
                radiusMiles={radiusMiles}
                zipCode={zipCode}
                county={county}
                resultMap={resultMap}
                singleFoundLabel={singleFoundLabel}
                startPrompt={startPrompt}
                title={typeAheadTitle}
                addDoctorLoader={addDoctorLoader}
                PER_PAGE={PER_PAGE}
            />
        </>
    );
};

export default DoctorSearch;
