import { useCallback, useEffect, useState, useContext, useMemo } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import {
    completeMedicareCurrentStep,
    previousMedicareStep,
    resetStepperState,
    skipMedicareCurrentStep
} from 'components/ConsumerGuidedExperienceModal/slice';
import CreateAccountBanner from 'components/CreateAccountBanner';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';
import YourDoctorModalContent from 'components/YourDoctorModalContent';
import { setPharmacyModal } from 'components/YourPharmacyModal/slice';

import prescriptionModalContext from 'contexts/prescriptionModal';

import { getProvidersSearchLookUp } from 'utilities/apiSession';
import {
    addProviders,
    deleteMultiLocationProvider
} from 'utilities/apiSession/provider';
import dataLayer from 'utilities/dataLayer';
import {
    DOCTOR_NAMES_KEY,
    DOCTOR_SEARCH_TERM_KEY
} from 'utilities/storageKeys';

import warningIcon from './icons/exclamation.svg';
import image from './image.svg';

import {
    headerText2,
    headerTitle,
    headerTitle2,
    headerTitle3,
    plansButtonLabel,
    buttonLabel,
    addDoctorsButtonLabel,
    updateLabel,
    updateLabelMobile
} from './constants';
import {
    setDoctorModal,
    setDoctorNames,
    setIsApiFailed,
    setIsSearchDoctor,
    setSelectedDoctor,
    setEditDoctorLoader,
    setSelectedPlan
} from './slice';

import styles, { mobileWidth } from './styles.module.scss';

import { setIsModalEdited } from 'temp/pages/Plans/slice';
import { checkModalDataHasChanged } from 'temp/utilities/modalHelper';
import { get, set } from 'temp/utilities/storage';

const YourDoctorModal = ({ onChange = () => null, handleAddDoctor }) => {
    const dispatch = useDispatch();
    const doctorModal = useSelector(state => state.doctorDetails.doctorModal);
    const isGuided = useSelector(state => state.guidedDetails.isGuidedProcess);
    const selectedPlan = useSelector(state => state.doctorDetails.selectedPlan);
    const syncId = useSelector(state => state.userProfile.userProfile.syncId);
    const [isDoctorsChanged, setIsDoctorsChanged] = useState(false);
    const { setPrescriptionModal } = useContext(prescriptionModalContext);
    const [selectedDoctors, setSelectedDoctors] = useState([]);
    const [selectedAddressCount, setSelectedAddressCount] = useState(0);
    const [showMaxReached, setShowMaxReached] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const getAuthToken = async () => {
        return user ? await getAccessTokenSilently() : '';
    };

    useEffect(() => {
        if (doctorModal) {
            dataLayer('modal_appear', headerTitle);
        }
    }, [doctorModal]);

    const initialDoctorsNames = useSelector(
        state => state.doctorDetails.doctorNames
    );
    const selectedDoctor = useSelector(
        state => state.doctorDetails.selectedDoctor
    );
    const isSearchDoctor = useSelector(
        state => state.doctorDetails.isSearchDoctor
    );

    const [doctorName, setDoctorName] = useState(
        get(DOCTOR_SEARCH_TERM_KEY, '')
    );

    const [doctorsList, setDoctorsList] = useState([]);
    const [footerButtonDisabled, setFooterButtonDisabled] = useState(false);
    const [isDoctorAddressSelected, setIsDoctorAddressSelected] =
        useState(false);

    const getProviders = useCallback(async () => {
        const handleDoctorsCount = doctorsList => {
            const doctorNames = doctorsList.map(item => {
                const { presentationName, NPI } = item;
                return { presentationName, NPI };
            });

            if (checkModalDataHasChanged(initialDoctorsNames, doctorNames)) {
                dispatch(setDoctorNames(doctorNames || []));
            }
            set(DOCTOR_NAMES_KEY, doctorNames);
        };
        const authToken = await getAuthToken();
        const data = await getProvidersSearchLookUp(authToken);
        if (!data) {
            dispatch(setIsApiFailed(true));
        } else {
            dispatch(setIsApiFailed(false));
        }
        const providers = data?.providers ?? [];

        if (providers?.length === 0) dispatch(setIsSearchDoctor(true));

        handleDoctorsCount(providers);
        setDoctorsList(providers);
    }, [dispatch, initialDoctorsNames]);

    useEffect(() => {
        if (doctorModal) getProviders();
    }, [doctorModal, getProviders]);

    const handleAddProvider = async () => {
        const authToken = await getAuthToken();
        const doctorPromises = selectedDoctors.reduce(
            (doctorPromise, selectedDoctor) => {
                const { NPI, selectedAddresses } = selectedDoctor;
                const payload = selectedAddresses.reduce((a, addressId) => {
                    a.push({
                        addressId: addressId,
                        isPrimary: false,
                        npi: String(NPI)
                    });
                    return a;
                }, []);
                doctorPromise.push(addProviders(payload, syncId, authToken));
                return doctorPromise;
            },
            []
        );
        await Promise.all(doctorPromises);
        setIsDoctorsChanged(true);
        await getProviders();
        if (handleAddDoctor) handleAddDoctor();
    };

    const onClose = isSkip => {
        if (!isSkip) {
            handleClear();
            dispatch(resetStepperState());
        }
        dispatch(setDoctorModal(false));

        if (isDoctorsChanged) {
            dispatch(setIsModalEdited(true));
        }
        dispatch(setSelectedDoctor(null));
        dispatch(setSelectedPlan(null));
        resetDoctorCount();
    };

    const handleBackButton = () => {
        if (selectedDoctor) dispatch(setSelectedDoctor(null));
        else {
            if (isSearchDoctor && doctorsList.length) {
                dispatch(setIsSearchDoctor(false));
            } else {
                dispatch(setDoctorModal(false));
                dispatch(setPharmacyModal(true));
                dispatch(previousMedicareStep());
            }
        }
        resetDoctorCount();
    };

    const handleSkipButton = () => {
        onClose(true);
        dispatch(setPharmacyModal(false));
        setPrescriptionModal(true);
        doctorsList.length > 0
            ? dispatch(completeMedicareCurrentStep())
            : dispatch(skipMedicareCurrentStep());
        resetDoctorCount();
    };

    const handleContinue = () => {
        if (isGuided) {
            dispatch(setDoctorModal(false));
            setPrescriptionModal(true);
            doctorsList.length > 0
                ? dispatch(completeMedicareCurrentStep())
                : dispatch(skipMedicareCurrentStep());
        }
        if (isDoctorsChanged) {
            dispatch(setIsModalEdited(true));
        }
        if (!selectedDoctor && !isSearchDoctor) onClose(true);
        onChange(true);
    };

    const footerButtonClicked = async e => {
        setFooterButtonDisabled(true);
        if (isSearchDoctor) {
            dispatch(setEditDoctorLoader(true));
            await handleAddProvider();
            dispatch(setEditDoctorLoader(false));
            dispatch(setIsSearchDoctor(false));
            setSelectedDoctors([]);
            if (!isGuided) handleContinue();
        } else {
            if (selectedDoctor) {
                await handleEditDoctor();
                dispatch(setSelectedDoctor(null));
                handleContinue();
            } else {
                if (!isGuided) {
                    dispatch(setIsSearchDoctor(true));
                }
                if (isGuided) handleContinue();
            }
        }
        setFooterButtonDisabled(false);
    };

    const updateDoctor = async () => {
        const { NPI, selectedAddresses, addresses } = selectedDoctor;
        const addressesToDelete = addresses.filter(
            address => !selectedAddresses.includes(address.id)
        );
        const deletePayload = addressesToDelete.reduce((a, { id }) => {
            a.push({
                addressId: id,
                npi: String(NPI)
            });
            return a;
        }, []);
        if (deletePayload.length)
            await deleteMultiLocationProvider(deletePayload, syncId);

        const addressIds = addresses.map(address => address.id);
        const addressesToAdd = selectedAddresses.filter(
            sa => !addressIds.includes(sa)
        );
        const payload = addressesToAdd.reduce((a, id) => {
            a.push({
                addressId: id,
                isPrimary: false,
                npi: String(NPI)
            });
            return a;
        }, []);
        const authToken = await getAuthToken();
        if (payload.length) await addProviders(payload, syncId, authToken);
    };

    const deleteDoctor = async () => {
        const { NPI, addresses } = selectedDoctor;
        const payload = addresses.reduce((a, { id }) => {
            a.push({
                addressId: id,
                npi: String(NPI)
            });
            return a;
        }, []);
        await deleteMultiLocationProvider(payload, syncId);
    };

    const handleEditDoctor = async () => {
        dispatch(setEditDoctorLoader(true));
        await updateDoctor();
        await getProviders();
        dispatch(setSelectedDoctor(null));
        dispatch(setEditDoctorLoader(false));
        setIsDoctorsChanged(true);
    };

    const handleDeleteDoctor = async () => {
        dispatch(setEditDoctorLoader(true));
        await deleteDoctor();
        await getProviders();
        dispatch(setSelectedDoctor(null));
        dispatch(setEditDoctorLoader(false));
        setIsDoctorsChanged(true);
    };

    const handleClear = e => {
        searchTermChanged('');
        setSelectedDoctors([]);
    };

    const handleDoctor = async (data, address) => {
        let tdoctors = selectedDoctors.filter(
            doctor => doctor.NPI !== data.NPI
        );
        if (address.length) {
            data['selectedAddresses'] = address;
            setSelectedDoctors([...tdoctors, data]);
        } else {
            setSelectedDoctors([...tdoctors]);
        }
    };

    useEffect(() => {
        setIsDoctorAddressSelected(Boolean(selectedDoctors.length));
        console.log(doctorsList);
        const existingDoctorsCount = doctorsList.reduce(
            (acc, item) => acc + item.addresses.length,
            0
        );
        const selectedDoctorsCount = selectedDoctors.reduce(
            (acc, item) => acc + item.selectedAddresses.length,
            0
        );
        const count = existingDoctorsCount + selectedDoctorsCount;
        setSelectedAddressCount(count);
        if (count >= 10) setShowMaxReached(true);
        else setShowMaxReached(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDoctors]);

    useEffect(() => {
        if (selectedDoctor) {
            const existingDoctorsExcludingSelected = doctorsList.filter(
                d => d.NPI !== selectedDoctor.NPI
            );
            const existingDoctorsCount =
                existingDoctorsExcludingSelected.reduce(
                    (acc, item) => acc + item.addresses.length,
                    0
                );
            const selectedDoctorCount =
                selectedDoctor.selectedAddresses?.length || 0;
            const count = existingDoctorsCount + selectedDoctorCount;
            setSelectedAddressCount(count);
            if (count >= 10) setShowMaxReached(true);
            else setShowMaxReached(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDoctor]);

    const resetDoctorCount = () => {
        const count = doctorsList.reduce(
            (acc, item) => acc + item.addresses.length,
            0
        );
        setSelectedAddressCount(count);
        if (count >= 10) setShowMaxReached(true);
        else setShowMaxReached(false);
    };

    useEffect(() => {
        resetDoctorCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doctorsList]);

    const searchTermChanged = searchTerm => {
        set(DOCTOR_SEARCH_TERM_KEY, searchTerm);
        setDoctorName(searchTerm);
    };

    const getTitle = useMemo(
        () =>
            isSearchDoctor
                ? headerTitle3
                : selectedDoctor
                ? headerTitle2
                : headerTitle,
        [selectedDoctor, isSearchDoctor]
    );

    const isFooterDisabled = useMemo(() => {
        if (isSearchDoctor && !isDoctorAddressSelected) return true;
        return false;
    }, [isDoctorAddressSelected, isSearchDoctor]);

    useEffect(() => {
        if (isGuided && initialDoctorsNames.length)
            dispatch(setIsSearchDoctor(false));
    }, [isGuided, dispatch, initialDoctorsNames]);

    const CountText = () => (
        <span className={styles.countText}>
            {10 - selectedAddressCount} additional locations.
        </span>
    );

    const getFooterLabel = () => {
        if (isSearchDoctor) {
            return isGuided ? buttonLabel : addDoctorsButtonLabel;
        }
        if (selectedDoctor) {
            return isMobile ? updateLabelMobile : updateLabel;
        }
        return isGuided ? plansButtonLabel : addDoctorsButtonLabel;
        // plansButtonLabel;
    };

    return (
        <>
            <Media
                queries={{
                    mobile: { maxWidth: mobileWidth }
                }}
                onChange={breakpoint => {
                    setIsMobile(breakpoint.mobile);
                }}
            />
            <Modal className={styles.modal} isOpen={doctorModal}>
                <ModalWindow
                    contentClassName={styles.contentClassName}
                    footerLabel={getFooterLabel()}
                    headerIcon={image}
                    headerText={headerText2}
                    headerTitle={getTitle}
                    onClose={() => onClose()}
                    footerButtonDisabled={
                        isFooterDisabled || footerButtonDisabled
                    }
                    footerButtonClicked={footerButtonClicked}
                    footerClassName={styles.footerClassName}
                    footerButtonClassName={styles.footerButtonClassName}
                    headerClassName={styles.headerClassName}
                    closeIconClassName={styles.closeIconClassName}
                    isSkip={isGuided}
                    isBack={selectedDoctor || isGuided}
                    skipButtonClicked={handleSkipButton}
                    backButtonClicked={handleBackButton}
                >
                    {showMaxReached && (
                        <Text className={styles.banner} id="maxReached">
                            <Icon
                                className={styles.warningIcon}
                                image={warningIcon}
                            />
                            You have reached the maximum of 10 locations
                            selected.
                        </Text>
                    )}
                    {!showMaxReached && selectedAddressCount > 0 && (
                        <Text
                            className={styles.banner}
                            id="selectedAddressCount"
                        >
                            You may select a maximum of 10 locations. Add up to
                            &nbsp;
                            <CountText />
                        </Text>
                    )}
                    <div
                        className={styles.content}
                        data-testid="your-doctor-modal-content"
                    >
                        {!isSearchDoctor && selectedPlan && (
                            <Text className={styles.planName}>
                                {selectedPlan?.planName}
                            </Text>
                        )}
                        <YourDoctorModalContent
                            defaultValue={doctorName}
                            doctorsList={doctorsList}
                            handleDeleteDoctor={handleDeleteDoctor}
                            handleDoctor={handleDoctor}
                            onChange={searchTermChanged}
                            onClear={handleClear}
                            maxReached={showMaxReached}
                            isGuided={isGuided}
                        />
                    </div>
                    {isGuided && false && <CreateAccountBanner />}
                </ModalWindow>
            </Modal>
        </>
    );
};

export default YourDoctorModal;
