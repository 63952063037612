import { Helmet } from 'react-helmet-async';

const Metadata = ({
    alt,
    description,
    image,
    largeImage,
    siteName = 'PlanEnroll',
    title,
    url = document.location.href
}) => (
    <Helmet>
        {alt && <meta name="twitter:image:alt" content={alt} />}

        {description && <meta name="description" content={description} />}

        {description && (
            <meta property="og:description" content={description} />
        )}

        {image && <meta property="og:image" content={image} />}

        {largeImage && <meta name="twitter:card" content={largeImage} />}

        <meta property="og:site_name" content={siteName} />

        {title && <title>{title}</title>}

        {title && <meta property="og:title" content={title} />}

        <meta property="og:url" content={url} />
    </Helmet>
);

export default Metadata;
