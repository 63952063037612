import moment from 'moment-timezone';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClientServiceContext } from 'services/clientServiceProvider';

import EditLink from 'components/EditLink';
import ListItem from 'components/ListItem';
import Text from 'components/Text';
import { setDoctorModal } from 'components/YourDoctorModal/slice';
import { setMedicaidModal } from 'components/YourMedicaidModal/slice';
import { setPharmacyModal } from 'components/YourPharmacyModal/slice';
import useFetch from 'components/hooks/useFetch';

import prescriptionModalContext from 'contexts/prescriptionModal';

import { CONSUMER_BASE_URL } from 'utilities/env';

import medicaid from './icons/medicaid.svg';
import medicare from './icons/medicareID.svg';
import pharmacy from './icons/pharmacy.svg';
import prescription from './icons/prescription.svg';
import doctors from './icons/providers.svg';
import zipcode from './icons/zipcode.svg';

import {
    MedicaidKey,
    PharmacyKey,
    PrescriptionKey,
    ProviderKey,
    ZipCodeKey
} from './constant';

import styles from './styles.module.scss';

import Pagination from 'temp/components/Pagination/pagination';

const ActivityStreamList = ({ onclick, openZipModal }) => {
    const pageSize = 5;
    const [activityStreamListData, setActivityStreamListData] = useState([]);
    const [pagedResults, setPagedResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { agentFirstName, agentLastName } = useSelector(
        state => state.planTypeDetail.medicareAgent
    ) || {
        agentFirstName: '',
        agentLastName: ''
    };
    const { firstName, lastName } = useSelector(
        state => state.userProfile.userProfile
    );
    const { clientService } = useClientServiceContext();
    const getConsumerId = () => {
        const consumerid = clientService.getConsumerid();
        return consumerid;
    };
    const consumerid = getConsumerId();
    const { Get: getActivities } = useFetch(
        `${CONSUMER_BASE_URL}/Activities/${getConsumerId()}`
    );

    const getAllActivities = async () => {
        const activityData = await getActivities();
        setActivityStreamListData(activityData);
        setPagedResults(activityData?.slice(0, pageSize));
    };

    useEffect(() => {
        if (consumerid) getAllActivities();
    }, [consumerid]);

    const getPageDetails = page => {
        if (currentPage !== page) {
            let currentAgentsPage;

            if (page === 'next') currentAgentsPage = currentPage + 1;
            else if (page === 'previous') currentAgentsPage = currentPage - 1;
            else currentAgentsPage = page;

            const pagedStart = (currentAgentsPage - 1) * pageSize;
            const pageLimit = pageSize * currentAgentsPage;
            const slicedResults = [...activityStreamListData]?.slice(
                pagedStart,
                pageLimit
            );
            setPagedResults(slicedResults);
            setCurrentPage(currentAgentsPage);
        }
    };
    const dispatch = useDispatch();
    const { setPrescriptionModal } = useContext(prescriptionModalContext);
    const openModal = updateLabel => {
        if (updateLabel.includes(PrescriptionKey)) setPrescriptionModal(true);
        else if (updateLabel.includes(ZipCodeKey)) openZipModal();
        else if (updateLabel.includes(MedicaidKey))
            dispatch(setMedicaidModal(true));
        else if (updateLabel.includes(PharmacyKey))
            dispatch(setPharmacyModal(true));
        else if (updateLabel.includes(ProviderKey))
            dispatch(setDoctorModal(true));
        else {
            onclick();
        }
    };
    const getImage = updateLabel => {
        if (updateLabel.includes(PrescriptionKey)) return prescription;
        else if (updateLabel.includes(MedicaidKey)) return medicaid;
        else if (updateLabel.includes(PharmacyKey)) return pharmacy;
        else if (updateLabel.includes(ProviderKey)) return doctors;
        else if (updateLabel.includes(ZipCodeKey)) return zipcode;
        else return medicare;
    };

    const ActivityStreamListDetails = () =>
        pagedResults ? (
            pagedResults.map((activityStream, index) => (
                <div key={index}>
                    <div
                        className={`${
                            index === 0 && currentPage === 1
                                ? styles.firstActivityStreamCard
                                : styles.activityStreamCard
                        }`}
                    >
                        <ListItem className={styles.listItem}>
                            <Text className={styles.date}>
                                {moment
                                    .utc(activityStream.createDate)
                                    .tz('America/Chicago')
                                    .format('MM/DD/YYYY')}
                            </Text>

                            <Text className={styles.userName}>
                                {activityStream.activitySource === 'Consumer'
                                    ? `${firstName}  ${lastName}`
                                    : agentFirstName
                                    ? `Agent ${agentFirstName} ${agentLastName}`
                                    : activityStream.activitySubject}
                            </Text>

                            <Text className={styles.updatedDetails}>
                                <EditLink
                                    className={styles.updatedDetailsEditLink}
                                    displayIconOnLeft={true}
                                    icon={getImage(activityStream.activityBody)}
                                    onClick={() =>
                                        openModal(activityStream.activityBody)
                                    }
                                    text={activityStream.activityBody}
                                />
                            </Text>

                            <Text className={styles.time}>
                                {`${moment
                                    .utc(activityStream.createDate)
                                    .tz('America/Chicago')
                                    .format('h:mm A')} CST`}
                            </Text>
                        </ListItem>
                    </div>
                </div>
            ))
        ) : (
            <></>
        );

    const NoResults = () => {
        return (
            <div className={styles.noResults}>
                <Text
                    className={styles.noResultsText}
                    text="No current activity"
                />
            </div>
        );
    };

    return (
        <>
            <div className={styles.activityStreamContainer}>
                <ActivityStreamListDetails />
            </div>
            {!activityStreamListData?.length && <NoResults />}
            {!!activityStreamListData?.length && (
                <Pagination
                    currentPlansPage={currentPage}
                    resultName="activity streams"
                    totalPages={Math.ceil(activityStreamListData?.length / 5)}
                    totalResults={activityStreamListData?.length}
                    pageSize={pageSize}
                    providerPagination={true}
                    visibleRange={3}
                    onPageChange={page => {
                        setCurrentPage(page);
                        getPageDetails(page);
                    }}
                />
            )}
        </>
    );
};

export default ActivityStreamList;
