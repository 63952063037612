import { createSlice } from '@reduxjs/toolkit';

import { MEDICAID_KEY } from 'utilities/storageKeys';

import { get } from 'temp/utilities/storage';

export const medicaidDetails = createSlice({
    name: 'medicaidDetails',

    initialState: {
        medicaidModal: false,
        selectedMedicaidOption: get(MEDICAID_KEY)
    },

    reducers: {
        setMedicaidModal: (state, action) => {
            state.medicaidModal = action.payload;
        },
        setSelectedMedicaidOption: (state, action) => {
            state.selectedMedicaidOption = action.payload;
        }
    }
});

export const { setMedicaidModal, setSelectedMedicaidOption } =
    medicaidDetails.actions;

export default medicaidDetails.reducer;
