import { useState } from 'react';
import { useSelector } from 'react-redux';

import Heading6 from 'components/Heading6';
import PharmacyListItem from 'components/PharmacyListItem';
import Spinner from 'components/Spinner';

import styles from './styles.module.scss';

const onClick =
    ({ deletePharmacy, pharmacyDetails, setLoading }) =>
    async () => {
        setLoading(true);
        await deletePharmacy(pharmacyDetails?.pharmacyRecordID);
        setLoading(false);
    };

const PharmacyAddedListItem = ({ deletePharmacy, pharmacyDetails }) => {
    const [loading, setLoading] = useState(false);
    const selectedPlan = useSelector(
        state => state.pharmacyDetails.selectedPlan
    );

    const {
        address1,
        address2,
        city,
        name,
        state,
        zip: zipCode
    } = pharmacyDetails;

    const { pharmacyCosts } = selectedPlan || {};
    let inNetwork = false;
    if (pharmacyCosts?.length) inNetwork = pharmacyCosts[0].isNetwork;
    return (
        <>
            <PharmacyListItem
                active={true}
                address1={address1}
                address2={address2}
                city={city}
                inOutNetwork={Boolean(inNetwork)}
                inNetworkIcon={inNetwork}
                key={name}
                name={name}
                state={state}
                zipCode={zipCode}
                selectPharmacy={styles.selectPharmacy}
            />

            <div className={styles.btnContainer}>
                {loading ? (
                    <Spinner />
                ) : (
                    <Heading6
                        className={styles.removePharmacy}
                        onClick={onClick({
                            deletePharmacy,
                            pharmacyDetails,
                            setLoading
                        })}
                        text="Remove Pharmacy"
                        id="removePharmacyLink"
                    />
                )}
            </div>
        </>
    );
};

export default PharmacyAddedListItem;
