import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import {
    setGuidedModal,
    setGuidedProcess
} from 'components/ConsumerGuidedExperienceModal/slice';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ModalWindow from 'components/ModalWindow';
import Text from 'components/Text';

import hamburgerContext from 'contexts/hamburger';
import zipcodeContext from 'contexts/zipCode';

import { PROFILE_ROUTE } from 'utilities/routes';
import { SELECTED_PLAN_TYPE } from 'utilities/storageKeys';

import finalExpenseIcon from './finalExpense.svg';
import medicareIcon from './medicare.svg';

import { finalExpensePlanType, medicarePlanType } from './constants';
import {
    setCheckPreferredAgentModal,
    setSelectedAgentType,
    setSelectedItem,
    setSelectedPlanType
} from './slice';

import styles from './styles.module.scss';

import { set } from 'temp/utilities/storage';

const PlanTypeModal = () => {
    const { setIsOpen } = useContext(hamburgerContext);
    const { zipCode } = useContext(zipcodeContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const selectedItem = useSelector(
        state => state.planTypeDetail.selectedItem
    );
    const onClose = () => dispatch(setSelectedItem(null));
    const onClick = url => {
        setIsOpen(false);
        onClose();
        if (
            PROFILE_ROUTE !== selectedItem.from ||
            (PROFILE_ROUTE === selectedItem.from && zipCode)
        )
            history.push(url);
    };

    const onSelectPlanType = planType => {
        dispatch(setSelectedPlanType(planType));
        dispatch(setSelectedAgentType(planType));
        dispatch(setCheckPreferredAgentModal(true));
        if (PROFILE_ROUTE === selectedItem.from && !zipCode) {
            dispatch(setGuidedProcess(true));
            dispatch(setGuidedModal(true));
            set(SELECTED_PLAN_TYPE, planType);
        }
        onClick(
            planType === medicarePlanType
                ? selectedItem.medicareLink
                : selectedItem.finalExpenseLink
        );
    };

    return selectedItem ? (
        <div className={styles.modalWithHeaderFooter}>
            <Modal className={styles.modal} isOpen={true}>
                <ModalWindow
                    closeIconClassName={styles.closeIconClassName}
                    contentClassName={styles.contentClassName}
                    headerClassName={styles.headerClassName}
                    headerTitle={'Choose Product Type'}
                    onClose={onClose}
                    hideFooter={true}
                    modalWindow={styles.modalWindow}
                >
                    <div
                        className={styles.content}
                        data-testid="plan-type-modal"
                    >
                        <div className={styles.tile}>
                            <Icon
                                alt="Medicare"
                                className={`${styles.medicareIcon} ${
                                    selectedItem?.disableMedicarePlanType
                                        ? styles.disableIcon
                                        : ''
                                }`}
                                image={medicareIcon}
                                onClick={
                                    !selectedItem?.disableMedicarePlanType
                                        ? () =>
                                              onSelectPlanType(medicarePlanType)
                                        : () => {}
                                }
                                id="medicareIcon"
                            />
                            <Text
                                className={`${styles.text} ${
                                    selectedItem?.disableMedicarePlanType
                                        ? styles.opacity3
                                        : ''
                                }`}
                                text="Health"
                                id="health"
                            />
                            <Text
                                className={`${styles.text1} ${
                                    selectedItem?.disableMedicarePlanType
                                        ? styles.opacity5
                                        : ''
                                }`}
                                text="( Medicare )"
                                id="medicare"
                            />
                        </div>
                        <div className={styles.tile}>
                            <Icon
                                alt="Final Expense"
                                className={`${styles.finalExpenseIcon} ${
                                    selectedItem?.disableFinalExpensePlanType
                                        ? styles.disableIcon
                                        : ''
                                }`}
                                image={finalExpenseIcon}
                                onClick={
                                    !selectedItem.disableFinalExpensePlanType
                                        ? () =>
                                              onSelectPlanType(
                                                  finalExpensePlanType
                                              )
                                        : () => {}
                                }
                                id="finalExpenseIcon"
                            />
                            <Text
                                className={`${styles.text} ${
                                    selectedItem?.disableFinalExpensePlanType
                                        ? styles.opacity3
                                        : ''
                                }`}
                                text="Life"
                                id="life"
                            />
                            <Text
                                className={`${styles.text1} ${
                                    selectedItem?.disableFinalExpensePlanType
                                        ? styles.opacity5
                                        : ''
                                }`}
                                text="( Final Expense )"
                                id="finalExpense"
                            />
                        </div>
                    </div>
                </ModalWindow>
            </Modal>
        </div>
    ) : (
        <></>
    );
};

export default PlanTypeModal;
