import { getLeadId } from '../../temp/utilities/apiSession/leadId';

import * as Sentry from '@sentry/react';

import deleteJSON from 'utilities/deleteJSON';
import { API_BASE_URL } from 'utilities/env';
import getJSON from 'utilities/getJSON';
import postJSON from 'utilities/postJSON';

const getUrl = () => {
    const leadId = getLeadId();
    return `${API_BASE_URL}/Lead/${leadId}/Pharmacies`;
};

const addPharmacy = async ({ data, syncId, authToken }) => {
    const {
        pharmacyRecordID,
        pharmacyID,
        isMailOrder,
        name,
        address1,
        address2,
        city,
        zip,
        state,
        pharmacyPhone,
        pharmacyIDType
    } = data;

    const body = {
        pharmacyRecordID,
        pharmacyID,
        isMailOrder,
        name,
        address1,
        address2,
        city,
        zip,
        state,
        pharmacyPhone,
        pharmacyIDType
    };

    let url = getUrl();
    if (syncId) url = `${url}/${syncId}`;
    try {
        return await postJSON({ url, body, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const deletePharmacy = async ({ id, syncId, authToken }) => {
    let url = `${getUrl()}/${id}`;
    if (syncId) url = `${url}/${syncId}`;
    try {
        return await deleteJSON({ url, authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

const getPharmacies = async (authToken = '') => {
    try {
        return await getJSON({ url: getUrl(), authToken });
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
};

export { addPharmacy, deletePharmacy, getPharmacies };
