import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    setSelectedQuickProfileItem,
    setTargetPage
} from 'components/QuickProfileHealthModal/slice';

import { CREATE_ACCOUNT_ROUTE } from 'utilities/routes';

import styles from './styles.module.scss';

const ListItem = ({ className, text, onClick, id }) => (
    <li className={className} onClick={onClick} id={id}>
        {text}
    </li>
);

const SingInListItems = ({
    className,
    manageAccount,
    handleLogout,
    handleLoginClick,
    isAuthenticated,
    handleClose
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentPage = window.location.href.substring(
        window.location.href.indexOf('#') + 1
    );
    const createAccount = () => {
        handleClose();
        if (
            window.location.href.includes('quick-profile') &&
            currentPage !== 'personalInformation' &&
            currentPage !== 'complete' &&
            window.location.hash &&
            !isAuthenticated
        ) {
            dispatch(setTargetPage('Sign_Up'));
            dispatch(setSelectedQuickProfileItem(CREATE_ACCOUNT_ROUTE));
        } else {
            history.push(CREATE_ACCOUNT_ROUTE);
        }
    };
    const login = () => {
        handleClose();
        if (
            window.location.href.includes('quick-profile') &&
            currentPage !== 'personalInformation' &&
            currentPage !== 'complete' &&
            window.location.hash &&
            !isAuthenticated
        ) {
            dispatch(setTargetPage('Login'));
            dispatch(setSelectedQuickProfileItem(CREATE_ACCOUNT_ROUTE));
        } else {
            handleLoginClick();
        }
    };
    return (
        <ul className={`${className} ${styles.option}`}>
            {isAuthenticated ? (
                <>
                    <ListItem
                        className={styles.listItem}
                        onClick={manageAccount}
                        text="Settings"
                        id={'manageAccountButton'}
                    />
                    <ListItem
                        className={styles.listItem}
                        onClick={handleLogout}
                        text="Logout"
                        id={'logoutButton'}
                    />
                </>
            ) : (
                <>
                    <ListItem
                        className={styles.listItem}
                        onClick={createAccount}
                        text="Sign Up"
                        id={'signUpButton'}
                    />
                    <ListItem
                        className={styles.listItem}
                        onClick={login}
                        text="Log In"
                        id={'logInButton'}
                    />
                </>
            )}
        </ul>
    );
};

export default SingInListItems;
