import Body2 from 'components/Body2';
import Heading2 from 'components/Heading2';
import Icon from 'components/Icon';
import IconWithLink from 'components/IconWithLink';
import LinkWithArrow from 'components/LinkWithArrow';
import ProfilePicture from 'components/ProfilePicture';
import Text from 'components/Text';

import arrow from './images/arrow.svg';
import online from './images/online.svg';

import styles from './styles.module.scss';

export const AgentAvailableComponent = ({
    labelClassName = '',
    hideText = false,
    text = 'Available Now',
    isNetworkAgent,
    toggleStatus
}) => {
    if (isNetworkAgent && toggleStatus) {
        return (
            <span className={styles.onlineStyle}>
                {' '}
                <Icon className={`${styles.onlineIcon}`} image={online} />
                {!hideText && (
                    <Body2
                        className={`${styles.availableText} ${labelClassName}`}
                        text={text}
                    />
                )}
            </span>
        );
    } else {
        return null;
    }
};

export const AgentAvailableComponentPurl = ({
    labelClassName = '',
    hideText = false,
    text = 'Available Now',
    isPurlToggledOn
}) => {
    if (isPurlToggledOn) {
        return (
            <span className={styles.onlineStyle}>
                {' '}
                <Icon className={`${styles.onlineIcon}`} image={online} />
                {!hideText && (
                    <Body2
                        className={`${styles.availableText} ${labelClassName}`}
                        text={text}
                    />
                )}
            </span>
        );
    } else {
        return null;
    }
};

export const PurlBannerAgentInfo = ({
    purlAgentData,
    handleClose,
    isMobile,
    onClickViewMore
}) => {
    const {
        isPurlToggledOn,
        agentProfessionalInfoResponse,
        agentCompletedTraining,
        agentToggleStatus
    } = purlAgentData || {};
    const { agentOfficeLocation: office, formattedHours = '' } =
        agentProfessionalInfoResponse || {};
    const { city = '', state = '' } = office || {};
    const isNetworkAgent = agentCompletedTraining === 'True';
    const toggleStatus = agentToggleStatus === 'True';

    return (
        <div className={styles.agentInfoContainer}>
            {isMobile && (
                <ProfilePicture
                    firstName={purlAgentData.agentFirstName}
                    lastName={purlAgentData.agentLastName}
                    profileUrl={purlAgentData.profileImageUrl}
                    hideCameraOverlay={true}
                />
            )}
            <div className={styles.purlAgentNameContainer}>
                <div className={styles.nameWithStatus}>
                    <Heading2
                        className={styles.purlAgentName}
                        text={`${purlAgentData.agentFirstName} ${purlAgentData.agentLastName}`}
                    />

                    <AgentAvailableComponentPurl
                        isPurlToggledOn={isPurlToggledOn}
                        labelClassName={styles.availableText}
                    />

                    {isMobile && <Text>NPN: {purlAgentData.agentNpn}</Text>}
                </div>

                {!isMobile && (
                    <Text className={styles.purlAgentSubText}>
                        {(city && city.length > 0) ||
                        (state && state.length > 0) ? (
                            <>
                                <Text className={styles.cityState}>
                                    <Text className={styles.cityState}>
                                        {`${[city, state]
                                            .filter(Boolean)
                                            .join(', ')} | `}
                                    </Text>
                                </Text>
                            </>
                        ) : (
                            <></>
                        )}

                        <>
                            <Text
                                className={styles.text}
                                text={formattedHours}
                            />

                            {formattedHours && (
                                <span className={styles.hideMobile}>|</span>
                            )}
                        </>

                        <Text className={styles.agentNpn}>
                            NPN: {purlAgentData.agentNpn}
                        </Text>
                    </Text>
                )}
                {!isMobile && (
                    <LinkWithArrow
                        text={'View More'}
                        className={styles.viewMore}
                        onClick={onClickViewMore}
                    />
                )}
                {localStorage.getItem('reqAgentData') && (
                    <IconWithLink
                        caption="Return to Map"
                        className={styles.iconWithLink}
                        iconClassName={styles.iconClassName}
                        image={arrow}
                        labelClassName={styles.labelClassName}
                        onClick={() => handleClose(true)}
                    />
                )}
            </div>
        </div>
    );
};
