import sanityClient from '@sanity/client';

import {
    SANITY_CLIENT_PROJECT_ID,
    SANITY_CLIENT_PROJECT_ENVIRONMENT
} from 'utilities/env';

export default sanityClient({
    projectId: SANITY_CLIENT_PROJECT_ID,
    dataset: SANITY_CLIENT_PROJECT_ENVIRONMENT,
    useCdn: true
});
